import database from '../../../firebaseConfig';
import { GladiatorCards } from './GladiatorCards';
import { ChestCards } from './ChestCards';
import '../../../i18n.ts';
import { useTranslation } from 'react-i18next';
import { React, useState, useEffect } from 'react';

export const Inventory = (props) => {
    const [chests, setChests] = useState([]);
    const [freeRenames, setFreeRenames] = useState(0);
    const [gladiators, setGladiators] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const concatReq = 'users/' + props.userAddress;
        const userRef = database.ref(concatReq);
        let gladiators;
        userRef.once('value').then(async (snapshot) => {
            setFreeRenames(await snapshot.val().freeRenames);
            setChests(await snapshot.val().chests);
            gladiators = await snapshot.val().gladiators;
            if(gladiators !== undefined){
                setGladiators(gladiators);
            }
        });
    },[props.userAddress]);
    document.getElementById("title-page").textContent = t('inventory')+" | CryptoBurpees";
    return ( 
    <>
        <GladiatorCards gladiators={gladiators} setGladiators={setGladiators} freeRenames={freeRenames} setFreeRenames={setFreeRenames} burpees={props.burpees} setBurpees={props.setBurpees} userAddress={props.userAddress} />
        <ChestCards userAddress={props.userAddress} chests={chests}/>
    </>
    )
}