import { useRef } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import '../../i18n.ts';
import { useTranslation } from 'react-i18next';

export default function Form(props) {
  const captchaRef = useRef(null);
  const { t } = useTranslation();
  return (
      <HCaptcha languageOverride={t('hcaptchaLanguage')}
        sitekey={process.env.REACT_APP_HCAPTHA_SITE_KEY}
        theme="dark"
        onVerify={props.setTokenHC}
        ref={captchaRef}
      />
  );
}