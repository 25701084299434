import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import 'remixicon/fonts/remixicon.css';
import { ethers } from "ethers";
import database from '../../src/firebaseConfig';
import metamaskIcon from '../images/icons/metamask.png';
import logotype from '../images/cryptoburpees logotype.svg';
import '../i18n.ts';
import i18n from '../i18n.ts';
import { useTranslation } from 'react-i18next';
import enFlag from '../images/flags/en.svg';
import esFlag from '../images/flags/es.svg';
import deFlag from '../images/flags/de.svg';
import ptFlag from '../images/flags/pt.svg';

export const Menu = (props) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  console.log(props.selectedLanguage);
  const [srcFlag, setSrcFlag] = useState(enFlag);
  const { t } = useTranslation();
  async function open(){
    let signer = null;
    let provider;
    if (window.ethereum == null) {
        console.log("MetaMask not installed; using read-only defaults")
        provider = ethers.getDefaultProvider()
    } else {
      try {
        provider = new ethers.BrowserProvider(window.ethereum)
        signer = await provider.getSigner();
        props.setUserAddress(signer.address.toLowerCase());
        props.setSigner(signer);
        props.setisLoged(true);
      } catch (error) {
        if (error.code === 4001) {
          console.log(t('youRejectedTheAccessRequest'));
        } else {
          console.error(t('errorRequestingAccountAccess'), error);
        }
      }
    }
  }
  let currentAccount = null;
  window.ethereum.request({ method: 'eth_accounts' })
    .then(handleAccountsChanged)
    .catch((err) => {
      console.error(err);
    });

  window.ethereum.on('accountsChanged', handleAccountsChanged);

  function handleAccountsChanged(accounts) {
    if (accounts.length === 0) {
      props.setUserAddress("");
      props.setisLoged(false);
    } else if (accounts[0] !== currentAccount) {
      currentAccount = accounts[0];
      props.setUserAddress(currentAccount);
      props.setisLoged(true);
    }
  }
  
  

  useEffect(() => {
      if(props.selectedLanguage === 'es'){ setSrcFlag(esFlag); } 
      else if(props.selectedLanguage === 'en'){ setSrcFlag(enFlag); }
      else if(props.selectedLanguage === 'de'){ setSrcFlag(deFlag); }
      else if(props.selectedLanguage === 'pt'){ setSrcFlag(ptFlag); }
      i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const concatReq = 'users/' + props.userAddress;
    const userRef = database.ref(concatReq);
    props.setSelectedLanguage(lng);
    userRef.child("lng").set(lng);
  }

  return (
  <>
    <nav className="navigation">
      <span className="brand-logo">
        <NavLink to="/">
          <img className='logo' src={logotype} alt='Crypto Burpees logotype' />
        </NavLink>
      </span>
      <span className='brand-name'>Crypto Burpees</span>
      <button className="hamburger" onClick={()=>{ setIsNavExpanded(!isNavExpanded); }} >
        {isNavExpanded ? <i className="ri-close-line ri-2x"></i> : <i className="ri-menu-fold-line ri-2x"></i>}
      </button>
      <div
        className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
          <ul>
            <NavLink to="https://t.me/cryptoburpees" target="_blank" className={({ isActive }) => isActive ? "active" : ""} >
              <button className='nav-button'><i className="ri-telegram-fill ri-xl mr-icons"></i>Telegram</button>
            </NavLink>
            <NavLink to="https://twitter.com/CryptoBurpees" target="_blank" className={({ isActive }) => isActive ? "active" : ""} >
              <button className='nav-button'><i className="ri-twitter-x-fill ri-xl mr-icons"></i>Twitter-X</button>
            </NavLink>
            <NavLink to="https://gonas-organization.gitbook.io/criptoburpees-or-white-paper/" target="_blank" className={({ isActive }) => isActive ? "active" : ""} >
              <button className='nav-button'><i className="ri-file-list-3-line ri-xl mr-icons"></i>White Paper</button>
            </NavLink>
            <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""} >
              <button className='nav-button'><i className="ri-home-6-line ri-xl mr-icons"></i>{t('home')}</button>
            </NavLink>
            {props.isLoged ? 
            <NavLink to="/dapp" className={({ isActive }) => isActive ? "active" : ""} >
              <button className='nav-button play-button'><i className="ri-login-circle-line ri-xl mr-icons"></i>{t('play')}</button>
            </NavLink>
            :
            <></>
            }
            {props.isLoged ? 
            <span>
              <button className='nav-button metamask-address' onClick={() => open()}><i className="ri-wallet-3-fill ri-xl mr-icons"></i>{props.userAddress.slice(0, 6)+"...."+props.userAddress.slice(-4)}</button>
            </span>
            :
            <span>
              <button className='nav-button' onClick={() => open()}>{t('loginWith')}<img alt='' className='icon-normal-size ml-icons' src={metamaskIcon} /></button>
            </span>
            }
            <span class="dropdown">
              <button class="dropdown-button flag-button"><img className='flag-img' alt='currently lng' src={srcFlag}/></button>
              <div class="dropdown-content">
                <button class="flag-button select" onClick={() => changeLanguage('es')}><img className='flag-img' alt='esp-flag' src={esFlag}/></button>
                <button class="flag-button select" onClick={() => changeLanguage('en')}><img className='flag-img' alt='eng-flag' src={enFlag}/></button>
                <button class="flag-button select" onClick={() => changeLanguage('de')}><img className='flag-img' alt='eng-flag' src={deFlag}/></button>
                <button class="flag-button select" onClick={() => changeLanguage('pt')}><img className='flag-img' alt='eng-flag' src={ptFlag}/></button>
              </div>
            </span>
          </ul>
      </div>
    </nav>
  </>
  );
};