import { React } from 'react';
import { format } from 'date-fns';
import '../../i18n.ts';
import { useTranslation } from 'react-i18next';

export const TransactionsTable = (props) => {
    const contTransactions = props.transactions.length;
    const { t } = useTranslation();
    const rows = [];
    for (let i = 0; i < contTransactions; i++) {
        rows.push(
            <tr className='transactions-tr' key={i} >
                <td>{(props.transactions[i].transactionType !== "DEPOSIT" ? <i className="ri-indeterminate-circle-line withdraw-type mr-icons"></i> : <i className="ri-add-circle-line deposit-type mr-icons"></i>)}{props.transactions[i].transactionType}</td>
                <td>{format(new Date(props.transactions[i].transactionDateTime), 'dd/MM/yyyy - HH:mm:ss')}</td>
                <td>{props.transactions[i].cryptocurrency}</td>
                <td>{props.transactions[i].value}</td>
                <td><a className='hash-url' target='_blank' rel='noreferrer' href={'https://testnet.bscscan.com/tx/'+props.transactions[i].hash}>...{props.transactions[i].hash.slice(-5)}</a></td>
            </tr>
        );
    }
    return ( 
        (rows.length !== 0) ?
        <>
            <table className='transactions-table'>
                <thead>
                    <tr>
                        <th>{t('typeOfTransaction')}</th>
                        <th>{t('dateAndTime')}</th>
                        <th>{t('cryptocurrency')}</th>
                        <th>{t('volume')}</th>
                        <th>Tx. Hash</th>
                    </tr>
                </thead>
                <tbody> 
                    {rows}
                </tbody>
            </table>
        </> : <><p>{t('noTransactionMoment')}</p></>
    );
}