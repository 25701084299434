import { React, useState, useEffect } from 'react';
import database from '../../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import 'react-toastify/dist/ReactToastify.css';
import Rodal from 'rodal';
import { default as StoreHCaptcha } from '../utilities/hcaptcha';
import { initWorkoutFunction } from "../utilities/WorkoutUtility";
import '../../i18n.ts';
import { useTranslation } from 'react-i18next';
// Assets imports
import unselected from '../../images/gladiators/unselected.png';
import bgDoBurpees1 from '../../images/bgburpees1.jpg';
import bgDoBurpees2 from '../../images/bgburpees2.jpg';
import bgDoBurpees3 from '../../images/bgburpees3.jpg';
// Common imports
import c1 from '../../images/gladiators/common/1.png';
import c2 from '../../images/gladiators/common/2.png';
import c3 from '../../images/gladiators/common/3.png';
import c4 from '../../images/gladiators/common/4.png';
import c5 from '../../images/gladiators/common/5.png';
import c6 from '../../images/gladiators/common/6.png';
// Rare imports
import r1 from '../../images/gladiators/rare/1.png';
import r2 from '../../images/gladiators/rare/2.png';
import r3 from '../../images/gladiators/rare/3.png';
import r4 from '../../images/gladiators/rare/4.png';
import r5 from '../../images/gladiators/rare/5.png';
import r6 from '../../images/gladiators/rare/6.png';
// Epic imports
import e1 from '../../images/gladiators/epic/1.png';
import e2 from '../../images/gladiators/epic/2.png';
import e3 from '../../images/gladiators/epic/3.png';
import e4 from '../../images/gladiators/epic/4.png';
import e5 from '../../images/gladiators/epic/5.png';
import e6 from '../../images/gladiators/epic/6.png';
// Legendary imports
import l1 from '../../images/gladiators/legendary/1.png';
import l2 from '../../images/gladiators/legendary/2.png';
import l3 from '../../images/gladiators/legendary/3.png';
import l4 from '../../images/gladiators/legendary/4.png';
import l5 from '../../images/gladiators/legendary/5.png';
import l6 from '../../images/gladiators/legendary/6.png';

export const DoBurpees = (props) => {
    const [gladiators, setGladiators] = useState([]);
    const [gladiatorSelectedData, setGladiatorSelectedData] = useState();
    const [srcImageDoBurpees, setSrcImageDoBurpees] = useState("");
    const [srcImage, setSrcImage] = useState("");
    const [tierStyle, setTierStyle] = useState("");
    const [tierText, setTierText] = useState("");
    const [allWorkoutsPrice, setAllWorkoutsPrice] = useState(0);
    const [workoutRows, setWorkoutRows] = useState([]);
    const [visible, setVisible] = useState(false);
    const [visibleWorkoutModal, setVisibleWorkoutModal] = useState(false);
    const [CBRPtoGive, setCBRPtoGive] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(0);
    const [tokenHC, setTokenHC] = useState(null);
    const { t } = useTranslation();
    const rows = [];
    let rowsWO = [];
    useEffect(() => {
        const concatReq = 'users/' + props.userAddress;
        const userRef = database.ref(concatReq);
        let gladiatorsLet = [];
        if(gladiators !== undefined && gladiators.length <= 0){
            userRef.once('value').then(async (snapshot) => {
                gladiatorsLet = await snapshot.val().gladiators;
                setGladiators(gladiatorsLet);
                setGladiatorSelectedData(gladiators[0]);
                setSrcImageDoBurpees(bgDoBurpees1);
            });
        }
    });

    if(gladiators !== undefined){
        for (let i = 0; i < gladiators.length; i++) {
            let tierStyle = "";
            let tierText = "";
            switch (gladiators[i].tier) {
                case "common":
                    tierStyle = "tier-emblem common-style";
                    tierText = t('common');
                break;
                case "rare":
                    tierStyle = "tier-emblem rare-style";
                    tierText = t('rare');
                break;
                case "epic":
                    tierStyle = "tier-emblem epic-style";
                    tierText = t('epic');
                break;
                case "legendary":
                    tierStyle = "tier-emblem legendary-style";
                    tierText = t('legendary');
                break;
                default:
                    break;
            }
            rows.push(
                <li className='gladiator-li' onClick={SelectGladiator} key={i} pos-gladiator={i} >
                    <span>{(gladiators[i].name !== "" ? gladiators[i].name : "Nº " + gladiators[i].id)}</span><span className={tierStyle}>{tierText}</span><span><span className={(gladiators[i].energia > 0 ? "true-workouts ml-icons" : "false-workouts ml-icons")}>{gladiators[i].energia}</span> / {gladiators[i].energiaMax}</span>
                </li>
            );
        }
    }

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
        setTokenHC(null);
        setCaptchaKey(prevKey => prevKey + 1);
    };

    const initAllWorkoutShowModal = () => {
        setVisibleWorkoutModal(true);
    };

    const AllWorkoutHideModal = () => {
        setVisibleWorkoutModal(false);
        setTokenHC(null);
        setCaptchaKey(prevKey => prevKey + 1);
    };
    
    function GetFeeTax(Hoy, Workout){
        const fechaHoy = new Date(Hoy);
        const fechaWorkOut = new Date(Workout);
        const diferenciaEnMilisegundos = fechaWorkOut - fechaHoy;
        const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
        let feeTax = 0;
        switch (diferenciaEnDias) {
            case 0: feeTax = 60; break;
            case -1: feeTax = 50; break;
            case -2: feeTax = 40; break;
            case -3: feeTax = 30; break;
            case -4: feeTax = 20; break;
            case -5: feeTax = 10; break;
            case -6: feeTax = 5; break;
            case -7: feeTax = 4; break;
            case -8: feeTax = 3; break;
            case -9: feeTax = 2; break;
            case -10: feeTax = 1; break;
            default: feeTax = 0; break;
        }
        return feeTax;
    }

    async function giveCBRP(cbrp, fee, date, posGlad){
        if(fee > 0){
            const feeCBRP = calculatePercentage(cbrp, fee);
            const CBRPtoGiveFee = Number(cbrp - feeCBRP).toFixed(2);
            document.getElementById("porcentaje-modal").textContent = fee + "%";
            document.getElementById("give-tokens-button").setAttribute("data-date", date);
            document.getElementById("give-tokens-button").setAttribute("data-pos", posGlad);
            document.getElementById("cbrp-give-modal").textContent = CBRPtoGiveFee;
            document.getElementById("cbrp-totalgive-modal").textContent = cbrp;
            setCBRPtoGive(CBRPtoGiveFee);
            show();
        } else {
            giveTokensToAcc(cbrp, date, posGlad);
        }
    }

    async function giveTokensToAcc(cbrp, date, posGladSelected){
        const loader = document.getElementById("loader-container");
        loader.classList.remove("hide-loader");
        loader.classList.add("show-loader");
        const functions = getFunctions();
        if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        }
        const giveCBRPToAcc = async () => {
            const instance = httpsCallable(functions, 'giveCBRPToAcc');
            instance([cbrp, props.userAddress, date, posGladSelected])
            .then(res => {
                toast(res.data.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: "custom-toast",
                    type: res.data.status,
                    autoClose: 5000,
                    draggable: true,
                    theme: "dark"
                });
                props.setBurpees(res.data.userCBRP);
                hide();
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
            }, err => {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                setTimeout(() => {
                    document.getElementById("loader-timer").textContent = 15;
                }, 1000);
            })
        }
        giveCBRPToAcc();
    }

    function calculatePercentage(cbrp, percentage) {
        if (percentage < 0 || percentage > 100) {
          throw new Error('El porcentaje debe estar entre 0 y 100');
        }
        let result = (cbrp * percentage) / 100;
        result = Math.ceil(result * 100) / 100;
        return result;
    }

    async function LoadDataGladiator(i){
        let elem = document.getElementById("dopurpees-img");
        let bgSelector = parseInt(elem.getAttribute("bg-selector"));
        bgSelector = bgSelector + 1;
        switch(bgSelector){
            default:
            case 1:
                setSrcImageDoBurpees(bgDoBurpees1);
                elem.setAttribute("bg-selector", "1");
                break;
            case 2:
                setSrcImageDoBurpees(bgDoBurpees2);
                elem.setAttribute("bg-selector", "2");
                break;
            case 3:
                setSrcImageDoBurpees(bgDoBurpees3);
                elem.setAttribute("bg-selector", "3");
                break;
        }
        setGladiatorSelectedData(gladiators[i]);
        if(gladiators[i].workouts !== undefined){
            rowsWO = [];
            const fechaActual = new Date();
            const OnlyDate = new Date(
                fechaActual.getFullYear(),
                fechaActual.getMonth(),
                fechaActual.getDate());
            const workoutsKeys = Object.keys(gladiators[i].workouts);
            for (let f = 0; f < workoutsKeys.length; f++) {
                const dayCBRP = gladiators[i].workouts[workoutsKeys[f]];
                const feeTax = GetFeeTax(OnlyDate, workoutsKeys[f]);
                rowsWO.push(
                    <div className='workouts-card' key={workoutsKeys[f]}>
                        <p>{workoutsKeys[f]} | <span className='cbrp-to-claim'>{dayCBRP}</span> CBRP</p>
                        <button onClick={() => giveCBRP(dayCBRP, feeTax, workoutsKeys[f], i)} className='open-chest-button'>{t('claimCBRP')} (-{feeTax}%)</button>
                    </div>
                )
            }
        }
        setWorkoutRows(rowsWO);
        // ponemos el boton de iniciar workout a disabled si el gladiador seleccionado tiene o no energia
        const initWorkoutBtn = document.getElementById("init-workout-btn");
        if(gladiators[i].energia > 0){ initWorkoutBtn.removeAttribute("disabled"); } else { initWorkoutBtn.setAttribute("disabled", true); }

        let lastIdNumber = parseInt(gladiators[i].id.split('')[gladiators[i].id.length - 1]);
        let srcImage = "";
        let tierStyle = "";
        let tierText = "";
        let allWorkoutPrice = "";
        switch (gladiators[i].tier) {
            case "common":
                tierStyle = "tier-emblem common-style";
                tierText = t('common');
                allWorkoutPrice = 2;
            break;
            case "rare":
                tierStyle = "tier-emblem rare-style";
                tierText = t('rare');
                allWorkoutPrice = 3;
            break;
            case "epic":
                tierStyle = "tier-emblem epic-style";
                tierText = t('epic');
                allWorkoutPrice = 4;
            break;
            case "legendary":
                tierStyle = "tier-emblem legendary-style";
                tierText = t('legendary');
                allWorkoutPrice = 5;
            break;
            default:
                break;
        }
        switch (lastIdNumber) {
            case 0:
            case 1: if(gladiators[i].tier === "common"){srcImage = c1; } else if (gladiators[i].tier === "rare"){srcImage = r1; } else if (gladiators[i].tier === "epic"){srcImage = e1; } else { srcImage = l1; } break;
            case 2:
            case 3: if(gladiators[i].tier === "common"){srcImage = c2; } else if (gladiators[i].tier === "rare"){srcImage = r2; } else if (gladiators[i].tier === "epic"){srcImage = e2; } else { srcImage = l2; } break;
            case 4:
            case 5: if(gladiators[i].tier === "common"){srcImage = c3; } else if (gladiators[i].tier === "rare"){srcImage = r3; } else if (gladiators[i].tier === "epic"){srcImage = e3; } else { srcImage = l3; } break;
            case 6:
            case 7: if(gladiators[i].tier === "common"){srcImage = c4; } else if (gladiators[i].tier === "rare"){srcImage = r4; } else if (gladiators[i].tier === "epic"){srcImage = e4; } else { srcImage = l4; } break;
            case 8: if(gladiators[i].tier === "common"){srcImage = c5; } else if (gladiators[i].tier === "rare"){srcImage = r5; } else if (gladiators[i].tier === "epic"){srcImage = e5; } else { srcImage = l5; } break;
            case 9: if(gladiators[i].tier === "common"){srcImage = c6; } else if (gladiators[i].tier === "rare"){srcImage = r6; } else if (gladiators[i].tier === "epic"){srcImage = e6; } else { srcImage = l6; } break;
            default:
                break;
        }
        setSrcImage(srcImage);
        setTierStyle(tierStyle);
        setTierText(tierText);
        setAllWorkoutsPrice(allWorkoutPrice);
    }

    async function SelectGladiator(event){
        const gladiatorPos = event.target.getAttribute("pos-gladiator");
        const gladiatorLiList = document.getElementsByClassName("gladiator-li");
        for (let i = 0; i < gladiatorLiList.length; i++) {
            const liElem = gladiatorLiList[i];
            if(liElem.classList.contains("active")){
                liElem.classList.remove("active");
            }
        }
        event.target.classList.add("active");
        await LoadDataGladiator(gladiatorPos);
    }

    async function initWorkout(event){
        event.target.setAttribute("disabled", true);
        const loaderBurpees = document.getElementById("loader-container-do-burpees");
        loaderBurpees.classList.remove("hide-loader");
        loaderBurpees.classList.add("show-loader");
        initTimer(14);
        setTimeout(async function(){
            const res = await initWorkoutFunction(gladiatorSelectedData.id, gladiatorSelectedData.energia, props.userAddress, 1);
            gladiators[parseInt(res.data.posGlad)].energia = parseInt(res.data.newEnergiaGlad);
            LoadDataGladiator(res.data.posGlad);
            toast(res.data.message + t('youEarned') + res.data.gainedBurpees + " CBRP!", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "custom-toast",
                type: res.data.status,
                autoClose: 10000,
                draggable: true,
                theme: "dark"
            });
            if(res.data.newEnergiaGlad !== 0) {
                event.target.removeAttribute("disabled");
            };
            loaderBurpees.classList.add("hide-loader");
            loaderBurpees.classList.remove("show-loader");
            setTimeout(() => {
                document.getElementById("loader-timer").textContent = 15;
            }, 1000);
        }, 14500);
    } 

    async function initAllWorkout(){
        document.getElementById("loader-timer").textContent = 30;
        const loaderBurpees = document.getElementById("loader-container-do-burpees");
        loaderBurpees.classList.remove("hide-loader");
        loaderBurpees.classList.add("show-loader");
        initTimer(29);
        setTimeout(async function(){
            const gladWorkouts = parseInt(gladiatorSelectedData.energia / 10);
            const res = await initWorkoutFunction(gladiatorSelectedData.id, gladiatorSelectedData.energia, props.userAddress, gladWorkouts, allWorkoutsPrice);
            gladiators[parseInt(res.data.posGlad)].energia = parseInt(res.data.newEnergiaGlad);
            LoadDataGladiator(res.data.posGlad);
            props.setBurpees(res.data.userBurpees);
            toast(res.data.message + t('youEarned') + res.data.gainedBurpees + " CBRP!", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "custom-toast",
                type: res.data.status,
                autoClose: 10000,
                draggable: true,
                theme: "dark"
            });
            loaderBurpees.classList.add("hide-loader");
            loaderBurpees.classList.remove("show-loader");
            AllWorkoutHideModal();
            setTimeout(() => {
                document.getElementById("loader-timer").textContent = 15;
            }, 1000);
        }, 29500);
    }

    function initTimer(contador){
        var timerELem = document.getElementById('loader-timer');
        var intervalo = setInterval(function() {
            timerELem.innerText = contador;
            contador--;
            if (contador === 0) {
                clearInterval(intervalo);
            }
        }, 1000);
    }
    
    document.getElementById("title-page").textContent = "Workout | CryptoBurpees";
    return ( 
    <>
    <ToastContainer />
    <div className="dapp-panel">
        <div className='grid-container'>
            <div className='gladiator-selected-panel'>
                {(gladiatorSelectedData !== undefined) ? 
                    <>
                    <div className='gladiator-details'>
                    <span>{(gladiatorSelectedData.name !== "" ? gladiatorSelectedData.name : "Nº " + gladiatorSelectedData.id)}</span><span className={tierStyle}>{tierText}</span>
                    </div>
                    <div className='gladiator-workouts'>
                        <span><span className={(gladiatorSelectedData.energia > 0 ? "true-workouts" : "false-workouts")}>{gladiatorSelectedData.energia}</span> / {gladiatorSelectedData.energiaMax}
                        <i className={(gladiatorSelectedData.energia > 0 ? "ml-icons energy-icon ri-water-flash-line" : "ml-icons energy-icon ri-zzz-line")}></i></span>
                    </div>
                    <div className='img-container'>
                        <img className='gladiator-card-img' src={srcImage} alt=''/>
                    </div>
                    </> : 
                    <>
                    <div className='gladiator-details'>
                        <span>Nº 00000</span><span className="tier-emblem unselected-style">{t('unselected')}</span>
                    </div>
                    <div className='gladiator-workouts'>
                        <span><span>00</span> / 00
                        <i className="ml-icons energy-icon ri-zzz-line"></i></span>
                    </div>
                    <div className='img-container'>
                        <img className='gladiator-card-img' src={unselected} alt=''/>
                    </div>
                    </>
                }
            </div>
            <div className='doburpees-panel'>
                <div className='img-container'>
                    <img id='dopurpees-img' bg-selector="1" className='bg-doburpees-img' src={srcImageDoBurpees} alt='' />
                    <button id='init-workout-btn' disabled={(gladiatorSelectedData !== undefined && gladiatorSelectedData.energia > 0 ? false : true )} onClick={initWorkout} className='init-workout-button'>{(gladiatorSelectedData !== undefined && gladiatorSelectedData.energia > 0 ? t('moveThatBelly') : t('noEnergy') )}</button>
                    <button id='init-all-workout-modal-btn' disabled={(gladiatorSelectedData !== undefined && props.burpees > allWorkoutsPrice && gladiatorSelectedData.energia > 0 ? false : true )} onClick={initAllWorkoutShowModal} className='init-all-workout-button'>{(gladiatorSelectedData !== undefined && gladiatorSelectedData.energia > 0 ? t('useAllEnergy'): t('noEnergy'))}</button>
                </div>
            </div>
            <div className='gladiators-list-panel'>
                <ul className='gladiators-ul'>
                    {rows}
                </ul>
            </div>
        </div>
    </div>
    <div className="dapp-panel">
        <h3><i className="ri-coins-fill not-bold mr-icons"></i>{t('CBRPToClaim')}</h3>
        {(workoutRows.length !== 0 ?
        <div className='workouts-card-group'>
            {workoutRows}
        </div>
        : 
        <p>{t('noCBRPClaimGladiator')}</p>
        )}
    </div>
    {/* <iframe title='2269761' data-aa='2269761' src='//ad.a-ads.com/2269761?size=728x90&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'728px', height:'90px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}></iframe> */}
    <Rodal className='custom-modal-style' enterAnimation='slideUp' width={305} height={325} visible={visible} onClose={hide}>
        <div>
            <h2>{t('importantAdvice')}</h2>
            <span>{t('modalAdviceText1')}<span id='porcentaje-modal'></span></span>
            <h4>{t('modalAdviceText2')}<span id='cbrp-give-modal'></span>{t('from')}<span id='cbrp-totalgive-modal'></span></h4>
            <StoreHCaptcha setTokenHC={setTokenHC} key={`captcha-${captchaKey}`} sitekey={process.env.REACT_APP_HCAPTHA_SITE_KEY} /><br/>
            <button id='give-tokens-button' disabled={tokenHC !== null ? false : true} data-pos data-date onClick={(event)=>giveTokensToAcc(CBRPtoGive, event.target.getAttribute("data-date"), event.target.getAttribute("data-pos"))} className='open-chest-button'>{tokenHC !== null ? <>{t('alsoClaim')}<i className="ml-icons ri-wallet-3-line"></i></> : <>{t('noVerification')}<i className="ml-icons ri-robot-2-line"></i></>}</button>
        </div>
    </Rodal>
    <Rodal className='custom-modal-style' enterAnimation='slideUp' width={355} height={355} visible={visibleWorkoutModal} onClose={AllWorkoutHideModal}>
        <div>
            <h2>{t('useAllEnergyUppercase')}</h2>
            <p>{t('modalAllEnergyText1')}<span className='true-workouts'>{(gladiatorSelectedData !== undefined ? gladiatorSelectedData.name !== "" ? gladiatorSelectedData.name : "Nº " + gladiatorSelectedData.id : "")} ({tierText})</span>{t('modalAllEnergyText2')}<span className='true-workouts'>{allWorkoutsPrice} CBRPs</span></p>
            <p>{t('modalAllEnergyText3')}<span className='true-workouts'>CAPTCHA</span></p>
            <StoreHCaptcha setTokenHC={setTokenHC} key={`captcha-${captchaKey}`} sitekey={process.env.REACT_APP_HCAPTHA_SITE_KEY} /><br/>
            <button id='init-all-workout-btn' disabled={tokenHC !== null ? false : true} onClick={initAllWorkout} className='open-chest-button'>{tokenHC !== null ? <>{t('startWorkOut')}</> : <>{t('noVerification')}<i className="ml-icons ri-robot-2-line"></i></>}</button>
        </div>
    </Rodal>
    {/* <iframe title='2269754' data-aa='2269754' src='//ad.a-ads.com/2269754?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}  className='float-izq-element1'></iframe>
    <iframe title='2269755' data-aa='2269755' src='//ad.a-ads.com/2269755?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}  className='float-izq-element2'></iframe>
    <iframe title='2269756' data-aa='2269756' src='//ad.a-ads.com/2269756?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}  className='float-izq-element3'></iframe>
    <iframe title='2269757' data-aa='2269757' src='//ad.a-ads.com/2269757?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}  className='float-der-element1'></iframe>
    <iframe title='2269758' data-aa='2269758' src='//ad.a-ads.com/2269758?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}  className='float-der-element2'></iframe>
    <iframe title='2269759' data-aa='2269759' src='//ad.a-ads.com/2269759?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}}  className='float-der-element3'></iframe> */}

    </>  
    )
}