import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        interpolation:{
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    appLoaderProcessTxt: 'Processing request...',
                    appLoaderBurpessTxt: "Gladiator doing burpees...",
                    home: 'Home',
                    play: 'PLAY',
                    loginWith: 'Login with',
                    youRejectedTheAccessRequest: 'You rejected the access request',
                    errorRequestingAccountAccess: 'Error requesting account access: ',
                    isHere: ' is HERE!',
                    iSoldMyFirstLambo: '"I sold my first lambo because it made me weak. I depended on that lambo..."',
                    homePText1: 'But since you havent had a single one, its about time you moved that belly and became ',
                    homePText2: 'Well... or have them made for you better, right?',
                    homePText3: 'Buy a ',
                    homePText4: ' to do burpees 24/7 and to generate you ',
                    homePText5: 'Depending on the years of experience of the ',
                    homePText6: ' in the gym the more you will generate.',
                    carruselTitle1: 'Some of the ',
                    carruselTitle2: 'Focking Gladiators',
                    typeOfTransaction: 'Type of transaction',
                    dateAndTime: 'Date and time',
                    cryptocurrency : 'Cryptocurrency',
                    volume: 'Volume',
                    noTransactionMoment: 'No transactions for the moment...',
                    store: 'Store',
                    transactions: 'Transactions',
                    goToDoBurpees: 'Go to do burpees',
                    inventory: 'Inventory',
                    chestForSale: 'CHESTS FOR SALE',
                    woodChest: 'WOOD chest',
                    silverChest: 'SILVER chest',
                    goldChest: 'GOLD chest',
                    fixedPrice: 'Fixed price: ',
                    buy: 'Buy',
                    lackOfUSDT: 'lack of USDT',
                    confirmPurchase: 'CONFIRM PURCHASE',
                    orderToProceed: 'In order to proceed with the purchase, you need to resolve the ',
                    finishShopping: 'Finish shopping',
                    noVerification: 'No verification...',
                    common: 'COMMON',
                    rare: 'RARE',
                    epic: 'EPIC',
                    legendary: 'LEGENDARY',
                    myFockingGladiators: 'MY FOCKING GLADIATORS',
                    titleModalEditName: 'NAME YOUR FOCKING GLADIATOR',
                    modalEditNameText1: 'The maximum number of characters is 9 ',
                    modalEditNameText2: 'with spaces included',
                    modalEditNameText3: 'Its price is ',
                    modalEditNameText4: ' if it does not contain free name changes.',
                    modalEditNameText5: 'Remaining free name changes: ',
                    insufficientCBRPAvailable: 'Insufficient CBRP available',
                    changeName: 'Change name',
                    titleModalEdrink: 'ENERGY DRINK PURCHASE',
                    modalEdrinkText1: 'The price of the Energy Drink for this gladiator is ',
                    modalEdrinkText2: 'The drink will give you ',
                    modalEdrinkText3: '10 extra energy',
                    modalEdrinkText4: ' your gladiator.',
                    modalEdrinkText5: 'Would you like to buy it?',
                    lookingGladiatorMiami: 'Looking for a gladiator in Miami...',
                    confirm: 'Confirm',
                    myChests: 'MY CHESTS',
                    openChest: 'Open chest',
                    tokenCopiedClipboard: 'Token copied to clipboard.',
                    hcaptchaLanguage: 'en',
                    amountTransferExceedsBalance: 'The amount of the transfer exceeds the balance',
                    declinedApprovalUSDTToken: 'You have declined approval with the USDT token.',
                    successfulTransactionUSDT: 'Successful transaction! Receiving USDT...',
                    cancelledUSDTTransaction: 'You have cancelled the USDT transaction',
                    errorSendingUSDTTransaction: 'Error sending USDT transaction',
                    declinedApprovalCBRPToken: 'You have declined approval with the CBRP token.',
                    successfulTransactionCBRP: 'Successful transaction! Receiving CBRP...',
                    cancelledCBRPTransaction: 'You have cancelled the CBRP transaction',
                    errorSendingCBRPTransaction: 'Error sending CBRP transaction',
                    successfulWithdrawingCBRP :'Successful transaction! Withdrawing CBRP...',
                    alreadyWithdrawnMaximumCBRP: 'You have already withdrawn todays maximum CBRP (2500 CBRP).',
                    approvesUSDTTokenContract: 'Approves the USDT token contract...',
                    approvesCBRPTokenContract: 'Approves the CBRP token contract...',
                    notEnoughUSDT: 'Not enough USDT!',
                    notEnoughCBRP: 'Not enough CBRP!',
                    withdraw: 'WITHDRAW',
                    withdrawAddress: 'Withdraw Address',
                    confirmWithdrawal: 'Confirm withdrawal',
                    confirmWithdrawalUppercase: 'CONFIRM WITHDRAWAL',
                    withdrawalConditions: 'Withdrawal conditions.',
                    noRestrictions: ': No restrictions.',
                    deposit: 'DEPOSIT',
                    depositAddress: 'Deposit Address',
                    depositConditions: 'Deposit conditions.',
                    confirmDeposit: 'Confirm deposit',
                    transactionsTableTitle: 'TRANSACTIONS',
                    modalWithdrawalText1: 'In order to proceed with the withdrawal, you have to resolve the ',
                    completingWithdrawal: 'Completing the withdrawal',
                    claimCBRP: 'Claim CBRP',
                    youEarned: '. You earned ',
                    unselected: 'UNSELECTED',
                    moveThatBelly: 'Move that belly',
                    noEnergy: 'No energy...',
                    useAllEnergy: 'Use all the energy',
                    useAllEnergyUppercase: 'USE ALL THE ENERGY',
                    CBRPToClaim: 'CBRP TO CLAIM',
                    noCBRPClaimGladiator: 'You have no CBRP to claim with this gladiator...',
                    importantAdvice: 'IMPORTANT ADVICE!',
                    modalAdviceText1: 'The early withdrawal commission is not 0%, but of ',
                    modalAdviceText2: 'The CBRP to be received are ',
                    alsoClaim: 'Also claim',
                    modalAllEnergyText1: 'The selected gladiator will be charged based on his rarity. In case of ',
                    modalAllEnergyText2: ', will be charged ',
                    modalAllEnergyText3: 'In order to continue with the training, you have to solve the ',
                    startWorkOut: 'Start Workout',
                    from: ' from ',
                }
            },
            es: {
                translation: {
                    appLoaderProcessTxt: 'Procesando solicitud...',
                    appLoaderBurpessTxt: "Gladiador haciendo burpees...",
                    home: 'Inicio',
                    play: 'JUGAR',
                    loginWith: 'Iniciar con',
                    youRejectedTheAccessRequest: 'Ha rechazado la solicitud de acceso',
                    errorRequestingAccountAccess: 'Error al solicitar acceso a la cuenta: ',
                    isHere: ' está AQUÍ!',
                    iSoldMyFirstLambo: '"Vendí mi primer lambo porque me hacía débil. Yo dependía de ese lambo..."',
                    homePText1: 'Pero como tú no has tenido ni uno, ya va siendo hora de que muevas esa panza y te hagas ',
                    homePText2: 'Bueno... o que los hagan por ti mejor, ¿no?',
                    homePText3: '¡Compra un ',
                    homePText4: ' para que haga burpees 24/7 y te genere ',
                    homePText5: 'Dependiendo de los años de experiencia del ',
                    homePText6: ' en el gym más generarás.',
                    carruselTitle1: 'Algunos de los ',
                    carruselTitle2: 'Focking Gladiadores',
                    typeOfTransaction: 'Tipo de transacción',
                    dateAndTime: 'Fecha y hora',
                    cryptocurrency: 'Cryptomoneda',
                    volume: 'Volumen',
                    noTransactionMoment: 'Sin transacciones por el momento...',
                    store: 'Tienda',
                    transactions: 'Operaciones',
                    goToDoBurpees: 'Ir a hacer burpees',
                    inventory: 'Inventario',
                    chestForSale: 'COFRES EN VENTA',
                    woodChest: 'Cofre de MADERA',
                    silverChest: 'Cofre de PLATA',
                    goldChest: 'Cofre de ORO',
                    fixedPrice: 'Precio fijo: ',
                    buy: 'Comprar',
                    lackOfUSDT: 'falta de USDT',
                    confirmPurchase: 'CONFIRMAR COMPRA',
                    orderToProceed: 'Para poder continuar con la compra, tiene que resolver el ',
                    finishShopping: 'Terminar de comprar',
                    noVerification: 'Sin verificación...',
                    common: 'COMÚN',
                    rare: 'RARO',
                    epic: 'ÉPICO',
                    legendary: 'LEGENDARIO',
                    myFockingGladiators: 'MIS FOCKING GLADIADORES',
                    titleModalEditName: 'NOMBRA A TU FOCKING GLADIADOR',
                    modalEditNameText1: 'El número máximo de caracteres es de 9 ',
                    modalEditNameText2: 'con espacios incluidos',
                    modalEditNameText3: 'Su precio es de ',
                    modalEditNameText4: ' si no contiene cambios de nombre gratuitos.',
                    modalEditNameText5: 'Cambios de nombre gratuito restantes: ',
                    insufficientCBRPAvailable: 'No dispone de suficiente CBRP',
                    changeName: 'Cambiar nombre',
                    titleModalEdrink: 'COMPRA DE ENERGY DRINK',
                    modalEdrinkText1: 'EL precio de la Energy Drink para este gladiador es de ',
                    modalEdrinkText2: 'La bebida le otorgará ',
                    modalEdrinkText3: '10 de energía extra',
                    modalEdrinkText4: ' a tu gladiador.',
                    modalEdrinkText5: '¿Desea comprarla?',
                    lookingGladiatorMiami: 'Buscando un gladiador por Miami...',
                    confirm: 'Confirmar',
                    myChests: 'MIS COFRES',
                    openChest: 'Abrir cofre',
                    tokenCopiedClipboard: 'Token copiado al portapapeles.',
                    hcaptchaLanguage: 'es',
                    amountTransferExceedsBalance: 'El monto de la transferencia excede el saldo',
                    declinedApprovalUSDTToken: 'Has rechazado la aprobación con el token USDT',
                    successfulTransactionUSDT: 'Transacción exitosa! Recibiendo USDT...',
                    cancelledUSDTTransaction: 'Has cancelado la transacción de USDT',
                    errorSendingUSDTTransaction: 'Error al enviar la transacción de USDT',
                    declinedApprovalCBRPToken: 'Has rechazado la aprobación con el token CBRP',
                    successfulTransactionCBRP: 'Transacción exitosa! Recibiendo CBRP...',
                    cancelledCBRPTransaction: 'Has cancelado la transacción de CBRP',
                    errorSendingCBRPTransaction: 'Error al enviar la transacción de CBRP',
                    successfulWithdrawingCBRP :'Transacción exitosa! Retirando CBRP...',
                    alreadyWithdrawnMaximumCBRP: 'Ya has retirado el maximo de CBRP de hoy! (2500 CBRP).',
                    approvesUSDTTokenContract: 'Aprueba el contrato del token USDT...',
                    approvesCBRPTokenContract: 'Aprueba el contrato del token CBRP...',
                    notEnoughUSDT: 'No tiene suficiente USDT!',
                    notEnoughCBRP: 'No tiene suficiente CBRP!',
                    withdraw: 'RETIRAR',
                    withdrawAddress: 'Dirección de retiro',
                    confirmWithdrawal: 'Confirmar retiro',
                    confirmWithdrawalUppercase: 'CONFIRMAR RETIRO',
                    withdrawalConditions: 'Condiciones de retiro.',
                    noRestrictions: ': Sin restricciones.',
                    deposit: 'DEPOSITAR',
                    depositAddress: 'Dirección de Deposito',
                    depositConditions: 'Condiciones de deposito',
                    confirmDeposit: 'Confirmar deposito',
                    transactionsTableTitle: 'TRANSACCIONES',
                    modalWithdrawalText1: 'Para poder continuar con el retiro, tiene que resolver el ',
                    completingWithdrawal: 'Terminar el retiro',
                    claimCBRP: 'Reclamar CBRP',
                    youEarned: '. Ganaste ',
                    unselected: 'SIN SELECCIONAR',
                    moveThatBelly: 'Mover esa panza',
                    noEnergy: 'Sin energia...',
                    useAllEnergy: 'Usar toda la energia',
                    useAllEnergyUppercase: 'USAR TODA LA ENERGIA',
                    CBRPToClaim: 'CBRP A RECLAMAR',
                    noCBRPClaimGladiator: 'No dispone de CBRP que reclamar con este gladiador...',
                    importantAdvice: '¡AVISO IMPORTANTE!',
                    modalAdviceText1: 'La comisión de retiro temprano no es de 0%, sino del ',
                    modalAdviceText2: 'Los CBRP a recibir son ',
                    alsoClaim: 'Reclamar igualmente',
                    modalAllEnergyText1: 'Al gladiador seleccionado se le cobrará en base a su rareza. En en caso de ',
                    modalAllEnergyText2: ', se le cobrará ',
                    modalAllEnergyText3: 'Para poder continuar con el entrenamiento, tiene que resolver el',
                    startWorkOut: 'Iniciar el entrenamiento',
                    from: ' de ',
                }
            },
            pt: {
                translation: {
                    appLoaderProcessTxt: 'A processar pedido...',
                    appLoaderBurpessTxt: 'Gladiador a fazer burpees...',
                    home: 'Início',
                    play: 'JOGAR',
                    loginWith: 'Iniciar com',
                    youRejectedTheAccessRequest: 'Rejeitou o pedido de acesso',
                    errorRequestingAccountAccountAccess: 'Erro ao pedir acesso à conta: ',
                    isHere: ' está AQUI!',
                    iSoldMyFirstLambo: '"Vendi o meu primeiro lambo porque me tornava fraco. Eu dependia daquele lambo..."',
                    homePText1: 'Mas como não tiveste um, já é altura de mexeres essa barriga e tornares-te ',
                    homePText2: 'Bem... ou manda fazer melhor para ti, não é?',
                    homePText3: 'Compra um ',
                    homePText4: ' para fazer burpees 24/7 e gerar para ti ',
                    homePText5: 'Dependendo dos anos de experiência de ',
                    homePText6: ' no ginásio, mais vai gerar.',
                    carouselTitle1: 'Alguns dos ',
                    carouselTitle2: 'Gladiadores de Foco',
                    typeOfTransaction: 'Tipo de transação',
                    dateAndTime: 'Data e hora',
                    cryptocurrency: 'Criptomoeda',
                    volume: 'Volume',
                    noTransactionMoment: 'Não há transacções neste momento...',
                    store: 'Loja',
                    transactions: 'Transacções',
                    goToDoBurpees: 'Ir fazer burpees',
                    inventory: 'Inventário',
                    chestForSale: 'COISAS PARA VENDA',
                    woodChest: 'CAIXA DE MADEIRA',
                    silverChest: 'CAIXA DE PRATA',
                    goldChest: 'CAIXA DE OURO',
                    fixedPrice: 'Preço fixo: ',
                    buy: 'Comprar',
                    lackOfUSDT: 'falta de USDT',
                    confirmPurchase: 'CONFIRMAR COMPRA',
                    orderToProceed: 'Para prosseguir com a compra, tem de resolver a ',
                    finishShopping: 'Terminar a compra',
                    noVerification: 'Sem verificação...',
                    comum: 'COMUM',
                    rare: 'RARO',
                    epic: 'EPIC',
                    legendary: 'LEGENDARY',
                    myFockingGladiators: 'MEUS GLADIADORES FOCADOS',
                    titleModalEditName: 'DÊ UM NOME AO SEU GLADIADOR DE FUTEBOL',
                    modalEditNameText1: 'O número máximo de caracteres é 9 ',
                    modalEditNameText2: 'incluindo espaços',
                    modalEditNameText3: 'O seu preço é ',
                    modalEditNameText4: 'se não contiver alterações de nome gratuitas',
                    modalEditNameText5: 'Restantes alterações de nome gratuitas: ',
                    insufficientCBRPAvailable: 'Não existe PFC suficiente disponível',
                    changeName: 'Alterar nome',
                    titleModalEdrink: 'COMPRA DE BEBIDA ENERGÉTICA',
                    modalEdrinkText1: 'O preço da bebida energética para este gladiador é ',
                    modalEdrinkText2: 'A bebida dá-lhe ',
                    modalEdrinkText3: '10 de energia extra',
                    modalEdrinkText4: 'ao teu gladiador',
                    modalEdrinkText5: "Queres comprá-la?",
                    lookingGladiatorMiami: 'Está à procura de um gladiador em Miami...',
                    confirm: 'Confirmar',
                    myChests: 'As minhas arcas',
                    openChest: 'Abrir baú',
                    tokenCopiedClipboard: 'Token copiado para a área de transferência',
                    hcaptchaLanguage: 'pt',
                    amountTransferExceedsBalance: 'El monto de la transferencia excede el saldo',
                    declinedApprovalUSDTToken: 'Has rechazado la aprobación con el token USDT',
                    successfulTransactionUSDT: 'Transacción exitosa! Recibiendo USDT...',
                    cancelledUSDTTransaction: 'Has cancelado la transacción de USDT',
                    errorSendingUSDTTransaction: 'Error al enviar la transacción de USDT',
                    declinedApprovalCBRPToken: 'Has rechazado la aprobación con el token CBRP',
                    successfulTransactionCBRP: 'Transacción exitosa! Recibiendo CBRP...',
                    cancelledCBRPTransaction: 'Has cancelado la transacción de CBRP',
                    errorSendingCBRPTransaction: 'Error al enviar la transacción de CBRP',
                    successfulWithdrawingCBRP :'Transacción exitosa! Retirando CBRP...',
                    alreadyWithdrawnMaximumCBRP: 'Ya has retirado el maximo de CBRP de hoy! (2500 CBRP).',
                    approvesUSDTTokenContract: 'Aprueba el contrato del token USDT...',
                    approvesCBRPTokenContract: 'Aprueba el contrato del token CBRP...',
                    notEnoughUSDT: 'No tiene suficiente USDT!',
                    notEnoughCBRP: 'No tiene suficiente CBRP!',
                    withdraw: "RETIRADA",
                    withdrawAddress: 'Endereço de retirada',
                    confirmWithdrawal: 'Confirmar a retirada',
                    confirmWithdrawalUppercase: "CONFIRMAR RETIRADA",
                    withdrawalConditions: 'Condições de retirada',
                    noRestrictions: ': Sem restrições.',
                    deposit: 'DEPÓSITO',
                    depositAddress: 'Endereço do depósito',
                    depositConditions: 'Condições do depósito',
                    confirmDeposit: "Confirmar depósito",
                    transactionsTableTitle: 'TRANSACÇÕES',
                    modalWithdrawalText1: 'Para continuar com a retirada, tem de resolver o problema ',
                    completingWithdrawal: 'Concluir a retirada',
                    claimCBRP: 'Reivindicar CBRP',
                    youEarned: '. YouEarned ',
                    unselected: 'NÃO SELECCIONADO',
                    moveThatBelly: 'Mexe essa barriga',
                    noEnergy: 'Sem energia...',
                    useAllEnergy: 'Utilizar toda a energia',
                    useAllEnergyUppercase: 'USE TODA A ENERGIA',
                    CBRPToClaim: 'CBRP PARA RECLAMAR',
                    noCBRPClaimGladiator: 'Não tem CBRP para reclamar com este gladiador...',
                    importantAdvice: 'AVISO IMPORTANTE!',
                    modalAdviceText1: 'A comissão de levantamento antecipado não é 0%, mas ',
                    modalAdviceText2: 'O CBRP a receber é ',
                    alsoClaim: 'Reivindicar de qualquer forma',
                    modalAllEnergyText1: 'O gladiador selecionado será cobrado com base na sua raridade. No caso de ',
                    modalAllEnergyText2: ', ser-lhe-á cobrado ',
                    modalAllEnergyText3: 'Para continuar o treino, tem de resolver o',
                    startWorkOut: 'Iniciar a formação',
                    from: ' de ',
                }
            },
            de: {
                translation: {
                    appLoaderProcessTxt: "Verzoek verwerken...",
                    appLoaderBurpessTxt: 'Gladiator doet burpees...',
                    home: "Home",
                    play: "SPELEN",
                    loginWith: "Inloggen met ",
                    youRejectedTheAccessRequest: 'U hebt het toegangsverzoek afgewezen, ',
                    errorRequestingAccountAccess: "Fout bij het aanvragen van toegang tot account: ",
                    isHere: ' is HIER!',
                    iSoldMyFirstLambo: '"Ik heb mijn eerste lambo verkocht omdat hij me zwak maakte. Ik was afhankelijk van die lambo...',
                    homePText1: 'Maar omdat je er geen hebt gehad, wordt het tijd dat je die buik verplaatst en ',
                    homePText2: 'Nou... of laat ze beter voor je maken, toch?',
                    homePText3: 'Koop een ',
                    homePText4: ' om 24/7 burpees te doen en je ',
                    homePText5: 'Afhankelijk van de jaren ervaring van ',
                    homePText6: ' in de sportschool hoe meer je zult genereren.',
                    carouselTitle1: 'Sommige van de ',
                    carouselTitle2: 'Focking Gladiators',
                    typeOfTransaction: "Soort transactie",
                    dateAndTime: "Datum en tijd",
                    cryptocurrency: "Cryptocurrency",
                    volume: "Volume",
                    noTransactionMoment: "Momenteel geen transacties...",
                    store: "Winkel",
                    transacties: "Transacties",
                    goToDoBurpees: "Ga burpees doen",
                    inventary: "Inventaris",
                    chestForSale: "KISTEN TE KOOP",
                    woodChest: "HOUTEN KIST",
                    silverChest: "ZILVER KIST",
                    goldChest: "GOUDEN KIST",
                    fixedPrice: "Vaste prijs: ",
                    buy: "Kopen",
                    lackOfUSDT: "Gebrek aan USDT",
                    confirmPurchase: "KOOP BEVESTIGEN",
                    orderToProceed: "Om verder te gaan met de aankoop, moet u de ",
                    finishShopping: "Beëindig het winkelen",
                    noVerification: "Geen verificatie...",
                    common: "GEMEENTE",
                    rare: "ZELDZAAM",
                    epic: "EPIC",
                    legendary: "LEGENDARIE",
                    myFockingGladiators: 'MIJN FOCKING GLADIATORS',
                    titleModalEditName: 'NAAM UW FOCKING GLADIATOR',
                    modalEditNameText1: 'Het maximum aantal tekens is 9 ',
                    modalEditNameText2: 'inclusief spaties',
                    modalEditNameText3: 'Uw prijs is ',
                    modalEditNameText4: ' als het geen gratis naamswijzigingen bevat.',
                    modalEditNameText5: 'Resterende gratis naamswijzigingen: ',
                    insufficientCBRPAvailable: 'Niet genoeg CBRP beschikbaar',
                    changeName: 'Naam wijzigen',
                    titleModalEdrink: 'AANKOOP ENERGIEDRANK',
                    modalEdrinkText1: 'De prijs van Energy Drink voor deze gladiator is ',
                    modalEdrinkText2: 'Met de drank krijg je ',
                    modalEdrinkText3: '10 extra energie',
                    modalEdrinkText4: ' aan je gladiator.',
                    modalEdrinkText5: 'Wil je het kopen?',
                    lookingGladiatorMiami: 'Op zoek naar een gladiator in Miami...', 
                    confirm: "Bevestigen",
                    myChests: "MIJN KASTEN",
                    openChest: " Open chest",
                    tokenCopiedClipboard: 'Token gekopieerd naar klembord',
                    hcaptchaLanguage: 'de',
                    amountTransferExceedsBalance: 'Overboekingsbedrag overschrijdt balans',
                    declinedApprovalUSDTToken: 'U hebt goedkeuring geweigerd met USDT token',
                    successfulTransactionUSDT: 'Transactie geslaagd! Ontvangen USDT...',
                    cancelledUSDTTransaction: 'U heeft de USDT transactie geannuleerd',
                    errorSendingUSDTTransaction: 'Fout bij verzenden USDT-transactie',
                    declinedApprovalCBRPToken: "Goedkeuring geweigerd met CBRP-token",
                    successfulTransactionCBRP: 'Transactie geslaagd! CBRP ontvangen...',
                    cancelledCBRPTransaction: 'U heeft de CBRP transactie geannuleerd',
                    errorSendingCBRPTransaction: 'Fout bij verzenden CBRP transactie',
                    successfulWithdrawingCBRP:'Transactie geslaagd! Opnemen CBRP...',
                    alreadyWithdrawnMaximumCBRP: "U heeft het maximale CBRP van vandaag (2500 CBRP) al opgenomen.",
                    approvesUSDTTokenContract: "Keurt het USDT tokencontract goed...",
                    approvesCBRPTokenContract: "keurt CBRP-tokencontract goed...",
                    notEnoughUSDT: 'Niet genoeg USDT!',
                    notEnoughCBRP: 'Niet genoeg CBRP!',
                    withdraw: 'INTREKKEN',
                    withdrawAddress: 'Opnameadres',
                    confirmWithdrawal: "Opname bevestigen",
                    confirmWithdrawalUppercase: "OPNAME BEVESTIGEN",
                    withdrawalConditions: "Intrekkingsvoorwaarden",
                    noRestrictions: ": Geen beperkingen.",
                    deposit: "DEPOSIT",
                    depositAddress: "Stortingsadres",
                    depositConditions: "Voorwaarden storting",
                    confirmDeposit: "Storting bevestigen",
                    transactionsTableTitle: "TRANSACTIES",
                    modalWithdrawalText1: 'Om door te gaan met de opname, moet u de ',
                    completingWithdrawal: "Opname voltooien",
                    claimCBRP: "Claim CBRP",
                    youEarned: '. JeWon ',
                    unselected: "NIET-GEKOZEN",
                    moveThatBelly: "Beweeg die buik",
                    noEnergy: 'Geen energie...',
                    useAllEnergy: 'Gebruik alle energie',
                    useAllEnergyUppercase: "GEBRUIK ALLE ENERGIE",
                    CBRPToClaim: "CBRP TO CLAIM",
                    noCBRPClaimGladiator: 'Je hebt geen CBRP te claimen met deze gladiator...',
                    importantAdvice: 'BELANGRIJKE WAARSCHUWING!',
                    modalAdviceText1: 'De kosten voor vervroegde opname zijn niet 0%, maar ',
                    modalAdviceText2: 'De te ontvangen CBRP is ',
                    alsoClaim: 'Toch claimen',
                    modalAllEnergyText1: 'De geselecteerde gladiator wordt afgerekend op basis van zijn zeldzaamheid. In het geval van ',
                    modalAllEnergyText2: ', moet hij ',
                    modalAllEnergyText3: 'Om door te gaan met de training, moet je de',
                    startWorkOut: 'Start de training',
                    from: ' van ',
                }
            }
        }
    });

export default i18n;