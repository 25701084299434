import woodChest from '../../images/chests/wood.png';
import silverChest from '../../images/chests/silver.png';
import goldChest from '../../images/chests/gold.png';

import { React, useState } from 'react';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { default as StoreHCaptcha } from '../utilities/hcaptcha';
import Rodal from 'rodal';
import '../../i18n.ts';
import { useTranslation } from 'react-i18next';

export const Store = (props) => {
    document.getElementById("title-page").textContent = "Tienda | CryptoBurpees";
    const [modalDatas, setModalDatas] = useState([{'chestType':null}]);
    const [visible, setVisible] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(0);
    const [tokenHC, setTokenHC] = useState(null);
    const { t } = useTranslation();

    async function buyChest(event){
        const loader = document.getElementById("loader-container");
        loader.classList.remove("hide-loader");
        loader.classList.add("show-loader");
        let chestSelected = await event.target.getAttribute("chest-type");
        event.target.setAttribute("disabled", true);
        const functions = getFunctions();
        if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        }
        const buyChest = async () => {
          const instance = httpsCallable(functions, 'buyChest');
          instance([chestSelected, props.userAddress])
          .then(res => {
            setTimeout(function(){
                toast(res.data.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: "custom-toast",
                    type: res.data.status,
                    autoClose: 5000, // 5000ms
                    draggable: true,
                    theme: "dark"
                });
                props.setUsd(res.data.usd);
                event.target.removeAttribute("disabled");
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                hide();
            }, 2500);
          }, err => {
            loader.classList.add("hide-loader");
            loader.classList.remove("show-loader");
            event.target.removeAttribute("disabled");
            hide();
          })
      }
      buyChest();
    } 

    async function setChestDataToModal(event){
        let chestSelected = await event.target.getAttribute("chest-type");
        setModalDatas([{'chestType':chestSelected}]);
        show();
    }

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
        setCaptchaKey(prevKey => prevKey + 1);
    };

    return ( 
        <>
        <ToastContainer />
        <div className="dapp-panel">
            <span className='float-usd-container'><span>{props.usd}</span> USDT</span>
            <h3><i className="ri-briefcase-4-fill mr-icons"></i>{t('chestForSale')}</h3>
            <div className='chest-card-group'>
                <div className='chest-card'>
                    <div className='chest-details'>
                        <span>{t('woodChest')}</span>
                    </div>
                    <div className='chest-price'>
                        <span>{t('fixedPrice')}10 USDT</span>
                    </div>
                    <img className='chest-card-img' src={woodChest} alt=''/>
                    <button type='button' chest-type="wood" disabled={(parseFloat(props.usd) >= 10 ? false : true )} onClick={setChestDataToModal} className='buy-button'>{(parseFloat(props.usd) >= 10 ? t('buy') : t('lackOfUSDT') )}<i className="ml-icons ri-shopping-cart-2-line"></i></button>
                </div> 

                <div className='chest-card'>
                    <div className='chest-details'>
                        <span>{t('silverChest')}</span>
                    </div>
                    <div className='chest-price'>
                        <span>{t('fixedPrice')}15 USDT</span>
                    </div>
                    <img className='chest-card-img' src={silverChest} alt=''/>
                    <button type='button' chest-type="silver" disabled={(parseFloat(props.usd) >= 15 ? false : true )} onClick={setChestDataToModal} className='buy-button'>{(parseFloat(props.usd) >= 15 ? t('buy') : t('lackOfUSDT') )}<i className="ml-icons ri-shopping-cart-2-line"></i></button>
                </div>
                <div className='chest-card'>
                    <div className='chest-details'>
                        <span>{t('goldChest')}</span>
                    </div>
                    <div className='chest-price'>
                        <span>{t('fixedPrice')}20 USDT</span>
                    </div>
                    <img className='chest-card-img' src={goldChest} alt=''/>
                    <button type='button' chest-type="gold" disabled={(parseFloat(props.usd) >= 20 ? false : true )} onClick={setChestDataToModal} className='buy-button'>{(parseFloat(props.usd) >= 20 ? t('buy') : t('lackOfUSDT') )}<i className="ml-icons ri-shopping-cart-2-line"></i></button>
                </div>
            </div>
        </div>
        <Rodal className='custom-modal-style' enterAnimation='slideUp' width={335} height={290} visible={visible} onClose={hide}>
            <div>
                <h2>{t('confirmPurchase')}</h2>
                <p>{t('orderToProceed')}<span className='true-workouts'>CAPTCHA</span></p>
                <StoreHCaptcha setTokenHC={setTokenHC} key={`captcha-${captchaKey}`} sitekey={process.env.REACT_APP_HCAPTHA_SITE_KEY} /><br/>
                <button type='button' disabled={tokenHC !== null ? false : true} chest-type={modalDatas[0].chestType !== null ? modalDatas[0].chestType : null} onClick={buyChest} className='buy-button'>{tokenHC !== null ? <>{t('finishShopping')}<i className="ml-icons ri-shopping-cart-2-line"></i></> : <>{t('noVerification')}<i className="ml-icons ri-robot-2-line"></i></>}</button>
            </div>
        </Rodal>
        </>  
    )
}