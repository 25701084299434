import { NavLink } from "react-router-dom";
import '../../i18n.ts';
import { useTranslation } from 'react-i18next';

export const SubMenu = (props) => {
    const { t } = useTranslation();
    return ( 
    <>
    <div className="margin-top-submenu">
        <nav className="navigation-dapp">
        <div className="navigation-menu-dapp">
            <ul>
                <NavLink to="/dapp/store" className={({ isActive }) => isActive ? "active" : ""} >
                <button className='nav-button upCase'><i className="ri-shopping-cart-2-line mr-icons"></i><span className="text-submenu-label">{t('store')}</span></button> 
                {/* Boton con submenu para compra caja o token en presale. Despues solo cajas. */}
                {/* <button className='nav-button-dapp'>Presale BRPs</button> */}
                </NavLink>
                <NavLink to="/dapp/transactions" className={({ isActive }) => isActive ? "active" : ""} >
                <button className='nav-button upCase'><i className="ri-token-swap-fill mr-icons"></i><span className="text-submenu-label">{t('transactions')}</span></button>
                {/* Aqui entraria como un historial con los movimientos del usuario, deposito y retiro */}
                </NavLink>
                <NavLink to="/dapp/doburpees" className={({ isActive }) => isActive ? "active" : ""} >
                <button className='nav-button upCase play-button'><i className="ri-run-fill mr-icons"></i><span className="text-submenu-label">{t('goToDoBurpees')}</span></button>
                </NavLink>
                <NavLink to="/dapp/inventory" className={({ isActive }) => isActive ? "active" : ""} >
                <button className='nav-button upCase'><i className="ri-briefcase-4-fill mr-icons"></i><span className="text-submenu-label">{t('inventory')}</span></button>
                </NavLink>
                <NavLink to="/dapp/userdata" className={({ isActive }) => isActive ? "active" : ""} >
                <button disabled className='nav-button upCase'><i className="ri-fire-fill mr-icons"></i><span className="text-submenu-label">{props.burpees} CBRP</span></button>
                </NavLink>
            </ul>
        </div>
        </nav>
    </div>
    </>  
    )
}