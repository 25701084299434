import { Outlet } from "react-router-dom";
import { SubMenu } from "./dappPages/SubMenu";
import '../../src/Dapp.css';
export const Dapp = (props) => {
    return ( 
    <>
    <div className='container-dapp'>
    {props.isLoged?
        <>
            <SubMenu burpees={props.burpees} />
            <Outlet />
        </>
        :
        <></>    
    }
    </div>
    </>  
    )
}