import { React, useState } from 'react';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { ToastContainer, toast } from 'react-toastify';
import Rodal from 'rodal';
import { default as StoreHCaptcha } from '../../utilities/hcaptcha';
import 'rodal/lib/rodal.css';
import '../../../i18n.ts';
import { useTranslation } from 'react-i18next';
// Chests imports
import wood from '../../../images/chests/wood.png';
import silver from '../../../images/chests/silver.png';
import gold from '../../../images/chests/gold.png';
// Common imports
import c1 from '../../../images/gladiators/common/1.png';
import c2 from '../../../images/gladiators/common/2.png';
import c3 from '../../../images/gladiators/common/3.png';
import c4 from '../../../images/gladiators/common/4.png';
import c5 from '../../../images/gladiators/common/5.png';
import c6 from '../../../images/gladiators/common/6.png';
// Rare imports
import r1 from '../../../images/gladiators/rare/1.png';
import r2 from '../../../images/gladiators/rare/2.png';
import r3 from '../../../images/gladiators/rare/3.png';
import r4 from '../../../images/gladiators/rare/4.png';
import r5 from '../../../images/gladiators/rare/5.png';
import r6 from '../../../images/gladiators/rare/6.png';
// Epic imports
import e1 from '../../../images/gladiators/epic/1.png';
import e2 from '../../../images/gladiators/epic/2.png';
import e3 from '../../../images/gladiators/epic/3.png';
import e4 from '../../../images/gladiators/epic/4.png';
import e5 from '../../../images/gladiators/epic/5.png';
import e6 from '../../../images/gladiators/epic/6.png';
// Legendary imports
import l1 from '../../../images/gladiators/legendary/1.png';
import l2 from '../../../images/gladiators/legendary/2.png';
import l3 from '../../../images/gladiators/legendary/3.png';
import l4 from '../../../images/gladiators/legendary/4.png';
import l5 from '../../../images/gladiators/legendary/5.png';
import l6 from '../../../images/gladiators/legendary/6.png';

export const ChestCards = (props) => {
    const [visible, setVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [srcModalImg, setSrcModalImg] = useState("");
    const [typeChestOpen, setTypeChestOpen] = useState("");
    const [tierText, setTierText] = useState("");
    const [tierStyle, setTierStyle] = useState("");
    const [idGladiator, setIdGladiator] = useState("");
    const [captchaKey, setCaptchaKey] = useState(0);
    const [tokenHC, setTokenHC] = useState(null);
    const [showCloseBtn, setShowCloseBtn] = useState(true);
    const { t } = useTranslation();

    const show = (typeChest) => {
        switch (typeChest) {
            case "wood":
                setTypeChestOpen("wood");
                setSrcModalImg(wood);
                break;
            case "silver":
                setTypeChestOpen("silver");
                setSrcModalImg(silver);
                break;
            case "gold":
                setTypeChestOpen("gold");
                setSrcModalImg(gold);
                break;
            default:
                break;
        }
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
        setTokenHC(false);
        setCaptchaKey(prevKey => prevKey + 1);
    };

    async function openChest(typeChestOpen){
        if(!isOpen){
            const functions = getFunctions();
            if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
                connectFunctionsEmulator(functions, "127.0.0.1", 5001);
            }
            const openChest = async () => {
                var imgOnModal = document.getElementById("chest-card-img-modal");
                imgOnModal.classList.add("invisibleElement");
                var openChestButton = document.getElementById("openChestButton");
                openChestButton.setAttribute("disabled", true);
                openChestButton.textContent = t('lookingGladiatorMiami');

                const instance = httpsCallable(functions, 'openChest');
                instance([typeChestOpen, props.userAddress])
                .then(res => {
                    setIdGladiator(res.data.id);
                    let lastIdNumber = parseInt(res.data.id.split('')[res.data.id.length - 1]);
                    switch (res.data.tier) {
                        case "common":
                            setTierStyle("tier-emblem common-style");
                            setTierText(t('common'));
                        break;
                        case "rare":
                            setTierStyle("tier-emblem rare-style");
                            setTierText(t('rare'));
                        break;
                        case "epic":
                            setTierStyle("tier-emblem epic-style");
                            setTierText(t('epic'));
                        break;
                        case "legendary":
                            setTierStyle("tier-emblem legendary-style");
                            setTierText(t('legendary'));
                        break;
                        default:
                            break;
                    }
                    switch (lastIdNumber) {
                        case 0:
                        case 1: if(res.data.tier === "common"){ setSrcModalImg(c1); } else if (res.data.tier === "rare"){ setSrcModalImg(r1); } else if (res.data.tier === "epic"){ setSrcModalImg(e1); } else { setSrcModalImg(l1); } break;
                        case 2:
                        case 3: if(res.data.tier === "common"){ setSrcModalImg(c2); } else if (res.data.tier === "rare"){ setSrcModalImg(r2); } else if (res.data.tier === "epic"){ setSrcModalImg(e2); } else { setSrcModalImg(l2); } break;
                        case 4:
                        case 5: if(res.data.tier === "common"){ setSrcModalImg(c3); } else if (res.data.tier === "rare"){ setSrcModalImg(r3); } else if (res.data.tier === "epic"){ setSrcModalImg(e3); } else { setSrcModalImg(l3); } break;
                        case 6:
                        case 7: if(res.data.tier === "common"){ setSrcModalImg(c4); } else if (res.data.tier === "rare"){ setSrcModalImg(r4); } else if (res.data.tier === "epic"){ setSrcModalImg(e4); } else { setSrcModalImg(l4); } break;
                        case 8: if(res.data.tier === "common"){ setSrcModalImg(c5); } else if (res.data.tier === "rare"){ setSrcModalImg(r5); } else if (res.data.tier === "epic"){ setSrcModalImg(e5); } else { setSrcModalImg(l5); } break;
                        case 9: if(res.data.tier === "common"){ setSrcModalImg(c6); } else if (res.data.tier === "rare"){ setSrcModalImg(r6); } else if (res.data.tier === "epic"){ setSrcModalImg(e6); } else { setSrcModalImg(l6); } break;
                        default:
                            break;
                    }
                    let gladiatorData = document.getElementById("gladiator-data");
                    gladiatorData.classList.remove("display-none");
                    imgOnModal.classList.add("visibleElement");
                    openChestButton.removeAttribute("disabled");
                    openChestButton.textContent = t('confirm');
                    setIsOpen(true);
                    setShowCloseBtn(false);
                    toast(res.data.message, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: res.data.status,
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }, err => {
                    console.log("ERROR: " + err);
                })
            }
        openChest();
        } else {
            window.location.reload(false);
        }
    } 

    const woodChests = props.chests.wood;
    const silverChests = props.chests.silver;
    const goldChests = props.chests.gold;
    const rows = [];
        if(woodChests !== 0){
            for (let i = 0; i < woodChests; i++) {
                rows.push(
                <div className='chest-card' key={"wood"+i} >
                    <div className='chest-details'>
                    <span>{t('woodChest')}</span>
                    </div>
                    <img className='chest-card-img inv-chest-img' onClick={() => show("wood")} src={wood} alt=''/>
                </div>);
            }
        }
        if(silverChests !== 0){
            for (let i = 0; i < silverChests; i++) {
                rows.push(
                <div className='chest-card' key={"silver"+i} >
                    <div className='chest-details'>
                    <span>{t('silverChest')}</span>
                    </div>
                    <img className='chest-card-img inv-chest-img' onClick={() => show("silver")} src={silver} alt=''/>
                </div>);
            }
        }
        if(goldChests !== 0){
            for (let i = 0; i < goldChests; i++) {
                rows.push(
                <div className='chest-card' key={"gold"+i} >
                    <div className='chest-details'>
                    <span>{t('goldChest')}</span>
                    </div>
                    <img className='chest-card-img inv-chest-img' key={"gold"+i} onClick={() => show("gold")} src={gold} alt=''/>
                </div>);
            }
        }
    return (
        (rows.length !== 0) ?
        <> 
        <div className='dapp-panel'>
            <h3><i className="ri-briefcase-4-fill mr-icons"></i>{t('myChests')}</h3>
            <div className='chest-card-group'>
                {rows}
            </div>
        </div>
        <Rodal closeMaskOnClick={false} closeOnEsc={false} showCloseButton={showCloseBtn} className='custom-modal-style' enterAnimation='slideUp' width={305} height={420} visible={visible} onClose={hide}>
            <div>
                <span id='gladiator-data' className='display-none'>Nº {idGladiator}</span><span className={tierStyle}>{tierText}</span>
                <img id='chest-card-img-modal' className='chest-card-img' src={srcModalImg} alt=''/><br/>
                <StoreHCaptcha setTokenHC={setTokenHC} key={`captcha-${captchaKey}`} sitekey={process.env.REACT_APP_HCAPTHA_SITE_KEY} /><br/>
                <button id='openChestButton' disabled={tokenHC !== null ? false : true} onClick={()=>openChest(typeChestOpen)} className='open-chest-button'>{tokenHC !== null ? <>{t('openChest')}<i className="ml-icons ri-briefcase-4-fill"></i></> : <>{t('noVerification')}<i className="ml-icons ri-robot-2-line"></i></>}</button>
            </div>
        </Rodal>
        <ToastContainer />
        </> : <></>
    );
}