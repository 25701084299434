import Flicking from "@egjs/react-flicking";
import { Perspective, AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/react-flicking/dist/flicking-inline.css";
import React, { useState } from 'react';
import '../i18n.ts';
import { useTranslation } from 'react-i18next';

import imgCarr1 from '../images/gladiators/legendary/1.png'
import imgCarr2 from '../images/gladiators/epic/1.png'
import imgCarr3 from '../images/gladiators/common/3.png'
import imgCarr4 from '../images/gladiators/rare/2.png'

export const Carrusel = () => {
    const { t } = useTranslation();
    const _plugins = [
         new Perspective({ rotate: 0.5 })
        ,new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: true })];
    const [panels] = useState([imgCarr1, imgCarr2, imgCarr3, imgCarr4]);
    return (
    <>
        <h2>{t('carruselTitle1')}<span className="enfasis-text">{t('carruselTitle2')}</span></h2>
        <Flicking circular={true} plugins={_plugins}>
            <div className="panel home-carrusel-panel"><img className="img-carrusel" alt="" src={panels[0]}/></div>
            <div className="panel home-carrusel-panel"><img className="img-carrusel" alt="" src={panels[1]}/></div>
            <div className="panel home-carrusel-panel"><img className="img-carrusel" alt="" src={panels[2]}/></div>
            <div className="panel home-carrusel-panel"><img className="img-carrusel" alt="" src={panels[3]}/></div>
      </Flicking>
    </>
    );
}