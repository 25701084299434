import React, { useState, useEffect } from 'react';
import database from '../../firebaseConfig';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import usdtCoin from "../../images/monedas/usdt.png"
import burpeesCoin from "../../images/monedas/cbrp token.svg"
import DAppContractJSON from "../../contracts/TokenDistributor.json"
import CBRPContract from "../../contracts/CryptoBurpeesToken.json"
import { ethers } from "ethers";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import '../../i18n.ts';
import { useTranslation } from 'react-i18next';
import { TransactionsTable } from './TransactionsTable';
import Rodal from 'rodal';
import { default as StoreHCaptcha } from '../utilities/hcaptcha';

export const Transactions = (props) => {
    const [transactions, setTransactions] = useState([]);
    const [approveUSDT, setApproveUSDT] = useState(false);
    const [approveCBRP, setApproveCBRP] = useState(false);
    const [maxInput, setMaxInput] = useState(2500);
    const [minInput, setMinInput] = useState(500);
    const [minDepositoInput, setMinDepositoInput] = useState(1);
    const [depositoDisabled, setDepositoDisabled] = useState(true);
    const [retiroDisabled, setRetiroDisabled] = useState(true);
    const [cryptoDeposit, setCryptoDeposit] = useState("BRP");
    const [cryptoRetiro, setCryptoRetiro] = useState("BRP");
    const { t } = useTranslation();
    const optionsRetiro = [
        { value: 'USDT', label: <div className='selected-option'><img src={usdtCoin} className='mr-icons coin-option-image' alt='USDT' /><span>USDT - Tether ({props.usd})</span></div>},
        { value: 'BRP', label: <div className='selected-option'><img src={burpeesCoin} className='mr-icons coin-option-image' alt='CBRP' /><span>CBRP - Burpees ({props.burpees})</span></div>}
    ];
    const optionsDeposito = [
        { value: 'USDT', label: <div className='selected-option'><img src={usdtCoin} className='mr-icons coin-option-image' alt='USDT' /><span>USDT - Tether</span></div>},
        { value: 'BRP', label: <div className='selected-option'><img src={burpeesCoin} className='mr-icons coin-option-image' alt='CBRP' /><span>CBRP - Burpees</span></div>}
    ];
    const [visible, setVisible] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(0);
    const [tokenHC, setTokenHC] = useState(null);

    document.getElementById("title-page").textContent = t('transactions') + " | CryptoBurpees";
    useEffect(() => {
        const concatReq = 'users/' + props.userAddress;
        const userRef = database.ref(concatReq);
        let transactionsDB;
        let approveUSDT_DB;
        let approveCBRP_DB;
        userRef.once('value').then(async (snapshot) => {
            transactionsDB = await snapshot.val().transactions;
            approveUSDT_DB = await snapshot.val().approveUSDT;
            approveCBRP_DB = await snapshot.val().approveCBRP;
            if(approveUSDT_DB !== undefined){
                setApproveUSDT(approveUSDT_DB);
            }
            if(approveCBRP_DB !== undefined){
                setApproveCBRP(approveCBRP_DB);
            }
            if(transactionsDB !== undefined){
                setTransactions(transactionsDB);
            }
        });
    },[props.userAddress]);

    setTimeout(async function(){
        if(props.signer == null){
            let provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            props.setSigner(signer);
        }
    }, 50);

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
        setCaptchaKey(prevKey => prevKey + 1);
    };

    const depositarUSDT = async () => {
        const loader = document.getElementById("loader-container");
        loader.classList.remove("hide-loader");
        loader.classList.add("show-loader");

        const value = document.getElementById("depositValue").value;
        const montoDeposito = ethers.parseEther(value);
        
        const tokenAddress = process.env.REACT_APP_MUSDT_TOKEN_CONTRACT_ADDRESS; // Reemplaza con la dirección del contrato del token
        const contratoAddress = process.env.REACT_APP_DAPP_CONTRACT_ADDRESS; // Dirección del contrato desplegado
        
        const DAppContract = new ethers.Contract(contratoAddress, DAppContractJSON.abi, props.signer);
        const tokenContract = new ethers.Contract(tokenAddress, process.env.REACT_APP_MUSDT_TOKEN_CONTRACT_ABI, props.signer);

        const allowance = await tokenContract.allowance(props.userAddress, contratoAddress);
        if (allowance < montoDeposito) { // Control de approve del token USDT
            try {
                const approveTx = await tokenContract.connect(props.signer).approve(contratoAddress, montoDeposito);
                await approveTx.wait();
                setApproveUSDTToDB();
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
            } catch (error) {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                if (error.info.error.code === 4001) {
                    toast(t('declinedApprovalUSDTToken'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "warning",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }
            }
        } else { // gasEstimate y deposit de USDT
            try {
                const gasEstimate = await DAppContract.deposit.estimateGas(tokenAddress, montoDeposito);
                const tx = await DAppContract.deposit(tokenAddress, montoDeposito, { gasLimit: gasEstimate });
                const receipt = await tx.wait();
                // Verifica si la transacción se ejecutó con éxito
                if (receipt.status === 1) {
                    loader.classList.add("hide-loader");
                    loader.classList.remove("show-loader");
                    toast(t('successfulTransactionUSDT'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "success",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                    const functions = getFunctions();
                    if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
                        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
                    }
                    const instance = httpsCallable(functions, 'depositUSDT');
                    instance([value, receipt.hash, props.userAddress])
                    .then(res => {
                        setTimeout(function(){
                            toast(res.data.message, {
                                position: toast.POSITION.BOTTOM_CENTER,
                                className: "custom-toast",
                                type: res.data.status,
                                autoClose: 5000, // 5000ms
                                draggable: true,
                                theme: "dark"
                            });
                            props.setUsd(res.data.userUSD);
                        }, 100);
                    })
                } else {
                    loader.classList.add("hide-loader");
                    loader.classList.remove("show-loader");
                    console.error('La transacción fue revertida');
                }
            } catch (error) {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                console.log(error.info.error.code);
                if (error.info.error.code === 4001) {
                    toast(t('cancelledUSDTTransaction'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "warning",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                } else {
                    toast(t('errorSendingUSDTTransaction'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "error",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }
            }
        }
    }

    const depositarCBRP = async () => {
        const loader = document.getElementById("loader-container");
        loader.classList.remove("hide-loader");
        loader.classList.add("show-loader");

        const value = document.getElementById("depositValue").value;
        const montoDeposito = ethers.parseEther(value);

        const tokenAddress = process.env.REACT_APP_CBRP_TOKEN_CONTRACT_ADDRESS; // Reemplaza con la dirección del contrato del token
        const contratoAddress = process.env.REACT_APP_DAPP_CONTRACT_ADDRESS; // Dirección del contrato desplegado

        const DAppContract = new ethers.Contract(contratoAddress, DAppContractJSON.abi, props.signer);
        const tokenContract = new ethers.Contract(tokenAddress, CBRPContract.abi, props.signer);

        const allowance = await tokenContract.allowance(props.userAddress, contratoAddress);
        if (allowance < montoDeposito) { // Control de approve del token CBRP
            try {
                const approveTx = await tokenContract.connect(props.signer).approve(contratoAddress, montoDeposito);
                await approveTx.wait();
                setApproveCBRPToDB();
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
            } catch (error) {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                if (error.info.error.code === 4001) {
                    toast(t('declinedApprovalCBRPToken'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "warning",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                } else {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                console.error('Error al enviar la transacción:', error);
                }
            }
        } else { // gasEstimate y deposit de CBRP
            try {
                const gasEstimate = await DAppContract.deposit.estimateGas(tokenAddress, montoDeposito);
                const tx = await DAppContract.deposit(tokenAddress, montoDeposito, { gasLimit: gasEstimate });
                const receipt = await tx.wait();
                // Verifica si la transacción se ejecutó con éxito
                if (receipt.status === 1) {
                    loader.classList.add("hide-loader");
                    loader.classList.remove("show-loader");
                    toast(t('successfulTransactionCBRP'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "success",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                    const functions = getFunctions();
                    if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
                        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
                    }
                    const instance = httpsCallable(functions, 'depositCBRP');
                    instance([value, receipt.hash, props.userAddress])
                    .then(res => {
                        setTimeout(function(){
                            toast(res.data.message, {
                                position: toast.POSITION.BOTTOM_CENTER,
                                className: "custom-toast",
                                type: res.data.status,
                                autoClose: 5000, // 5000ms
                                draggable: true,
                                theme: "dark"
                            });
                            props.setBurpees(res.data.userBurpees);
                        }, 100);
                    })
                } else {
                    loader.classList.add("hide-loader");
                    loader.classList.remove("show-loader");
                    console.error('La transacción fue revertida');
                }
            } catch (error) {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                if (error.info.error.code === 4001) {
                    toast(t('cancelledCBRPTransaction'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "warning",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                } else if(error.info.error.code === -32603){
                    toast(t('amountTransferExceedsBalance'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "error",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }
                else {
                    toast(t('errorSendingCBRPTransaction'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "error",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }
            }
        }
    }

    async function setApproveCBRPToDB() {
        const concatReq = 'users/' + props.userAddress;
        const userRef = database.ref(concatReq);
        await userRef.child("approveCBRP").set(true);
        setApproveCBRP(true);
    }

    async function setApproveUSDTToDB() {
        const concatReq = 'users/' + props.userAddress;
        const userRef = database.ref(concatReq);
        await userRef.child("approveUSDT").set(true);
        setApproveUSDT(true);
    }

    const retiroCBRP = async () => {
        const loader = document.getElementById("loader-container");
        loader.classList.remove("hide-loader");
        loader.classList.add("show-loader");
        const value = document.getElementById("retirarValue").value;
        const montoRetiro = ethers.parseEther(value);
        const tokenAddress = process.env.REACT_APP_CBRP_TOKEN_CONTRACT_ADDRESS; // Reemplaza con la dirección del contrato del token
        const contratoAddress = process.env.REACT_APP_DAPP_CONTRACT_ADDRESS; // Dirección del contrato desplegado
        const pass = process.env.REACT_APP_DAPP_CONTRACT_PASS;
        const DAppContract = new ethers.Contract(contratoAddress, DAppContractJSON.abi, props.signer);
        try {
            const gasEstimate = await DAppContract.withdraw.estimateGas(tokenAddress, montoRetiro, pass);
            const tx = await DAppContract.withdraw(tokenAddress, montoRetiro, pass, { gasLimit: gasEstimate });
            const receipt = await tx.wait();
            if (receipt.status === 1) {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                toast(t('successfulWithdrawingCBRP'), {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: "custom-toast",
                    type: "success",
                    autoClose: 5000, // 5000ms
                    draggable: true,
                    theme: "dark"
                });
                const functions = getFunctions();
                if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
                    connectFunctionsEmulator(functions, "127.0.0.1", 5001);
                }
                const instance = httpsCallable(functions, 'retiroCBRP');
                instance([value, receipt.hash, props.userAddress])
                .then(res => {
                    setTimeout(function(){
                        toast(res.data.message, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            className: "custom-toast",
                            type: res.data.status,
                            autoClose: 5000, // 5000ms
                            draggable: true,
                            theme: "dark"
                        });
                        props.setBurpees(res.data.userBurpees);
                    }, 100);
                })
            } else {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                console.error('La transacción fue revertida');
            }
        } catch (error) {
            loader.classList.add("hide-loader");
            loader.classList.remove("show-loader");
            if (error.info.error.code === 4001) {
                toast(t('cancelledCBRPTransaction'), {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: "custom-toast",
                    type: "warning",
                    autoClose: 5000, // 5000ms
                    draggable: true,
                    theme: "dark"
                });
            } else {
                toast(t('alreadyWithdrawnMaximumCBRP'), {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: "custom-toast",
                    type: "error",
                    autoClose: 5000, // 5000ms
                    draggable: true,
                    theme: "dark"
                });
            }
        }
    }

    function handleChangeRetiro(){
        let val = document.getElementById("retirarValue").value;
        if (val.includes('.')) {
            let parts = val.split('.');
            if (parts[1].length > 2) {
                document.getElementById("retirarValue").value = parseFloat(val).toFixed(2);
            }
        }
        if(val < maxInput && val >= minInput){
            setRetiroDisabled(false);
        } else {
            setRetiroDisabled(true);
        }
    }

    function handleChangeDeposito(){
        let val = document.getElementById("depositValue").value;
        if (val.includes('.')) {
            let parts = val.split('.');
            if (parts[1].length > 2) {
                document.getElementById("depositValue").value = parseFloat(val).toFixed(2);
            }
        }
        if(val >= minDepositoInput){
            setDepositoDisabled(false);
        } else {
            setDepositoDisabled(true);
        }
        
    }

    function depositCryptocurrencies(){
        switch(cryptoDeposit){
            case "USDT": 
                if(!approveUSDT){
                    toast(t('approvesUSDTTokenContract'), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "custom-toast",
                        type: "warning",
                        autoClose: 10000,
                        draggable: true,
                        theme: "dark"
                    });
                }
                depositarUSDT(); // cambiar usdt cuando solucione el cbrp
            break;
            case "BRP": 
                if(!approveCBRP){
                    toast(t('approvesCBRPTokenContract'), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: "custom-toast",
                        type: "warning",
                        autoClose: 10000,
                        draggable: true,
                        theme: "dark"
                    });
                }
                depositarCBRP();
            break;
            default: break;
        }
    }

    function retirarCryptocurrencies(){
        var val = document.getElementById("retirarValue").value;
        switch(cryptoRetiro){
            case "USDT": 
                let usd = parseFloat(props.usd);
                let valueusd = parseFloat(val);
                if(usd >= valueusd){
                    console.log("retirar usd...");
                } else {
                    toast(t('notEnoughUSDT'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "error",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }
            break;
            case "BRP": 
                let burpeescbrp = parseFloat(props.burpees);
                let valuecbrp = parseFloat(val);
                if(burpeescbrp >= valuecbrp){
                    retiroCBRP();
                } else {
                    toast(t('notEnoughCBRP'), {
                        position: toast.POSITION.BOTTOM_CENTER,
                        className: "custom-toast",
                        type: "error",
                        autoClose: 5000, // 5000ms
                        draggable: true,
                        theme: "dark"
                    });
                }
            break;
            default: break;
        }
    }
    
    return ( 
    <>
    <ToastContainer />
    <div className="double-container">
        <div className="dapp-panel operations-panel">
            <h3><i className="ri-arrow-up-circle-line mr-icons"></i>{t('withdraw')}</h3>
            <div className='double-container'>
                <Select className='cryptocurrency-selector' 
                    options = {optionsRetiro}
                    defaultValue={optionsRetiro[1]}
                    isSearchable={false}
                    onChange={(newValue) => {
                        switch(newValue.value){
                            case "USDT": 
                                setMinInput(1); 
                                setMaxInput(9999);
                                setCryptoRetiro("USDT");
                            break;
                            case "BRP": 
                                setMinInput(500); 
                                setMaxInput(2501);
                                setCryptoRetiro("BRP");
                            break;
                            default: break;
                        }
                        document.getElementById("retirarValue").value = undefined;
                        setRetiroDisabled(true);
                      }}
                />
                <div className='custom-input-container'>
                    <input id='retirarValue' type="number" onChange={handleChangeRetiro} min={minInput} max={maxInput} step="2" placeholder={props.burpees} required />
                </div>
            </div>
            <div className='address-zone'>
                <p><small>{t('withdrawAddress')}</small></p>
                <p className='address-zone-useraddress'>{props.userAddress}</p>
            </div>
            <button disabled={retiroDisabled} onClick={show} className='retiro-button'><i className="ri-arrow-up-circle-line mr-icons"></i>{t('confirmWithdrawal')}</button>
            <p><i className="ri-alert-fill danger-color mr-icons"></i>{t('withdrawalConditions')}<br></br>Min. 500 CBRP, Max. 2500 CBRP. <i>USDT</i>{t('noRestrictions')}</p>
        </div> {/* PANEL DE RETIRO */}
        <div className="dapp-panel operations-panel">
            <h3><i className="ri-arrow-down-circle-line mr-icons"></i>{t('deposit')}</h3>
            <div className='double-container'>
                <Select className='cryptocurrency-selector' 
                    options = {optionsDeposito}
                    defaultValue={optionsDeposito[1]}
                    isSearchable={false}
                    onChange={(newValue) => {
                        switch(newValue.value){
                            case "USDT": 
                                setCryptoDeposit("USDT");
                                setMinDepositoInput(10);
                            break;
                            case "BRP": 
                                setCryptoDeposit("BRP");
                                setMinDepositoInput(1);
                            break;
                            default: break;
                        }
                        document.getElementById("depositValue").value = undefined;
                        setDepositoDisabled(true);
                      }}
                />
                <div className='custom-input-container'>
                    <input id='depositValue' type="number" min={minDepositoInput} onChange={handleChangeDeposito} step="0.01" placeholder='0' required />
                </div>
            </div>
            <div className='address-zone'>
                <p><small>{t('depositAddress')}</small></p>
                <p className='address-zone-useraddress'>{props.userAddress}</p>
            </div>
            <button onClick={depositCryptocurrencies} disabled={depositoDisabled} className='deposito-button'><i className="ri-arrow-down-circle-line mr-icons"></i>{t('confirmDeposit')}</button>
            <p><i className="ri-alert-fill danger-color mr-icons"></i>{t('depositConditions')}<br></br>Min. 10 USDT. <i>CBRP</i>{t('noRestrictions')}</p>
        </div> {/* PANEL DE DEPOSITO */}
        <div className='dapp-panel operations-transactions-panel'>
            <h3><i className="ri-exchange-funds-line mr-icons"></i>{t('transactionsTableTitle')}</h3>
                <TransactionsTable userAddress={props.userAddress} transactions={transactions} />
        </div>
        <Rodal className='custom-modal-style' enterAnimation='slideUp' width={335} height={290} visible={visible} onClose={hide}>
            <div>
                <h2>{t('confirmWithdrawalUppercase')}</h2>
                <p>{t('modalWithdrawalText1')}<span className='true-workouts'>CAPTCHA</span></p>
                <StoreHCaptcha setTokenHC={setTokenHC} key={`captcha-${captchaKey}`} sitekey={process.env.REACT_APP_HCAPTHA_SITE_KEY} /><br/>
                <button type='button' disabled={tokenHC !== null ? false : true} onClick={retirarCryptocurrencies} className='buy-button'>{tokenHC !== null ? <>{t('completingWithdrawal')}<i className="ml-icons ri-shopping-cart-2-line"></i></> : <>{t('noVerification')}<i className="ml-icons ri-robot-2-line"></i></>}</button>
            </div>
        </Rodal>
    </div>
    </>  
    )
}