import { Carrusel } from './Carrusel';
import { ToastContainer, toast } from 'react-toastify';
import '../i18n.ts';
import { useTranslation } from 'react-i18next';

export const Home = () => {
  const { t } = useTranslation();
  document.getElementById("title-page").textContent = t('home') + " | CryptoBurpees";
  function copyAddress(address){
    navigator.clipboard.writeText(address);
    toast(t('tokenCopiedClipboard'), {
      position: toast.POSITION.BOTTOM_CENTER,
      className: "custom-toast",
      type: "success",
      autoClose: 2500, // 5000ms
      draggable: true,
      theme: "dark"
  });
  }
  
  return (
  <>
  <div className='container-home'>
    <h1><i>¡IS REAL!</i> ¡<span className='enfasis-text'>Crypto Burpees</span>{t('isHere')}</h1>
    <div className='inline-divs'>
      <div className='card-home'>
        <h3><i>{t('iSoldMyFirstLambo')}</i></h3>
        <p className=''>
          {t('homePText1')}<span className='enfasis-text'>¡50 FOCKING BURPEES!</span><br></br>{t('homePText2')}<br></br>
          {t('homePText3')}<span className='enfasis-text'>Focking Gladiador</span>{t('homePText4')}<span className='enfasis-text'>$CBRP!</span><br></br>
          {t('homePText5')}<span className='enfasis-text'>Focking Gladiador</span>{t('homePText6')}
        </p>
      </div>
      <hr className='hr-color'/>
      <div className='card-home'>
        <button className='copy-button' onClick={()=> copyAddress(process.env.REACT_APP_CBRP_TOKEN_CONTRACT_ADDRESS)}>CBRP Token: {process.env.REACT_APP_CBRP_TOKEN_CONTRACT_ADDRESS}</button>
        <button className='copy-button' onClick={()=> copyAddress(process.env.REACT_APP_MUSDT_TOKEN_CONTRACT_ADDRESS)}>MUST Token: {process.env.REACT_APP_MUSDT_TOKEN_CONTRACT_ADDRESS}</button>
      </div>
      <hr className='hr-color'/>
      <div className='card-home'>
        <Carrusel/>
      </div>
    </div>
  </div>

  {/* <iframe title='2269737' data-aa='2269737' src='//ad.a-ads.com/2269737?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}} className='float-izq-element1'></iframe>
  <iframe title='2269740' data-aa='2269740' src='//ad.a-ads.com/2269740?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}} className='float-izq-element2'></iframe>
  <iframe title='2269741' data-aa='2269741' src='//ad.a-ads.com/2269741?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}} className='float-izq-element3'></iframe>
  <iframe title='2269742' data-aa='2269742' src='//ad.a-ads.com/2269742?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}} className='float-der-element1'></iframe>
  <iframe title='2269743' data-aa='2269743' src='//ad.a-ads.com/2269743?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}} className='float-der-element2'></iframe>
  <iframe title='2269744' data-aa='2269744' src='//ad.a-ads.com/2269744?size=300x250&background_color=0e1a1d&text_color=ffffff&title_color=49fff0&title_hover_color=43dccf&link_color=49fff0&link_hover_color=43dccf' style={{width:'300px', height:'250px', border:'0px', padding:'0', overflow:'hidden', backgroundColor: 'transparent'}} className='float-der-element3'></iframe> */}

  <ToastContainer />
  </>
  )
}