import database from '../../firebaseConfig';
import moment from 'moment'; 

export async function initWorkoutFunction(gladId, gladEnergia, userAddress, timesToExecute, priceByAll){
  return new Promise(async (resolve) => {
    let status = "";
    let message = "";
    let gladiatorsList;
    let gladiatorSelected;
    let response;
    if (userAddress != null && userAddress !== "") {
      const concatReq = "users/" + userAddress;
      const userRef = database.ref(concatReq);
      userRef.once("value").then(async (snapshot) => {
        gladiatorsList = await snapshot.val().gladiators;
        if (gladiatorsList !== undefined) {
          await gladiatorsList.forEach((gladiator) => {
            if (gladiator.id === gladId && gladiator.energia === gladEnergia) {
              gladiatorSelected = gladiator;
            }
          });
        }
        const userBurpees = parseFloat(snapshot.val().burpees).toFixed(2);
        if (gladiatorSelected !== undefined) {
          let place1percent = 0;
          let place2percent = 0;
          let place3percent = 0;
          let place4percent = 0;
          switch (gladiatorSelected.tier) {
            case "common":
              place1percent = 0.05;
              place2percent = 0.12;
              place3percent = 0.22;
              place4percent = 0.4;
              break;
            case "rare":
              place1percent = 0.10;
              place2percent = 0.22;
              place3percent = 0.44;
              place4percent = 0.72;
              break;
            case "epic":
              place1percent = 0.20;
              place2percent = 0.45;
              place3percent = 0.65;
              place4percent = 0.87;
              break;
            case "legendary":
              place1percent = 0.30;
              place2percent = 0.57;
              place3percent = 0.82;
              place4percent = 0.95;
              break;
            default:
              break;
          }
          let totalGladiatorBurpees = 0;
          for(let i = 0; i < timesToExecute; i++){
            const randomNumberPlace = Math.random();
            let gladiatorBurpees = 0;
            if (randomNumberPlace <= place1percent) {
              gladiatorBurpees = 10;
            } else if (randomNumberPlace <= place2percent) {
              gladiatorBurpees = 7.5;
            } else if (randomNumberPlace <= place3percent) {
              gladiatorBurpees = 5;
            } else if (randomNumberPlace <= place4percent) {
              gladiatorBurpees = 2.5;
            } else {
              gladiatorBurpees = 1.25;
            }
            totalGladiatorBurpees += gladiatorBurpees;
          }
          if (timesToExecute !== 1) {
            await userRef.child("burpees")
              .set(parseFloat(userBurpees - parseInt(priceByAll)).toFixed(2));
          }
          let posGladiator = 0;
          let posGladiatorSelected = 0;
          let newEnergiaGlad = 0;
          gladiatorsList.forEach(async (gladiator) => {
            if (gladiator.id === gladId) {
              posGladiatorSelected = posGladiator;
              newEnergiaGlad = Math.round(parseInt(gladiator.energia - parseInt(10 * timesToExecute)));
              await userRef.child("/gladiators")
                  .child(posGladiatorSelected)
                  .child("energia").set(newEnergiaGlad);
            }
            posGladiator++;
          });
          let OnlyDateFormated = moment().utc().format('yyyy-MM-DD');
          const workOutsDays = snapshot.val()
              .gladiators[posGladiatorSelected]
              .workouts;
          if (workOutsDays !== undefined) {
            const workOutsDaysKeys = Object.keys(workOutsDays);
            const elementoEncontrado = workOutsDaysKeys
                .find((day) => day === OnlyDateFormated);
            if (elementoEncontrado) {
              const workOutsDayVal = Number(workOutsDays[elementoEncontrado]);
              const finalGainedCBRP = workOutsDayVal + totalGladiatorBurpees;
              await userRef
                  .child("/gladiators")
                  .child(posGladiatorSelected)
                  .child("/workouts")
                  .child(OnlyDateFormated)
                  .set(finalGainedCBRP);
            } else {
              await userRef.child("/gladiators")
                  .child(posGladiatorSelected)
                  .child("/workouts").child(OnlyDateFormated)
                  .set(totalGladiatorBurpees);
            }
          } else {
            await userRef.child("/gladiators").child(posGladiatorSelected)
                .child("/workouts").child(OnlyDateFormated)
                .set(totalGladiatorBurpees);
          }
            status = "success";
            message = "Burpees completados con exito";
            response = {
              status: "success",
              data: {
                "newEnergiaGlad": newEnergiaGlad,
                "posGlad": posGladiatorSelected,
                "gainedBurpees": totalGladiatorBurpees,
                "id": gladiatorSelected.id,
                "status": status,
                "message": message,
                "userBurpees": parseFloat(userBurpees - parseInt(priceByAll)).toFixed(2),
              },
            };
            resolve(response);
          } else {
            resolve({
              status: "success",
              data: {
              "newEnergiaGlad": "",
              "posGlad": "",
              "gainedBurpees": "",
              "id": "",
              "status": "error",
              "message": "error",
              "userBurpees":  "",
            }});
          }
      });
    }
  })
}