import { Menu } from './components/Menu';
import { Home } from './components/Home';
import { Dapp } from './components/Dapp';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import database from './firebaseConfig';
import { React, useState, useEffect } from 'react';
import { Store } from './components/dappPages/Store';
import { Transactions } from './components/dappPages/Transactions';
import { DoBurpees } from './components/dappPages/DoBurpees';
import { Inventory } from './components/dappPages/Inventory/Inventory';
import './i18n.ts';
import { useTranslation } from 'react-i18next';

function App() {
  const [userAddress, setUserAddress] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState();
  const [signer, setSigner] = useState(null);
  const [burpees, setBurpees] = useState(0);
  const [usd, setUsd] = useState(0);
  const [isLoged, setisLoged] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if(isLoged){
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + 1);
      tomorrow.setHours(0);
      tomorrow.setMinutes(0);
      tomorrow.setSeconds(0);
      tomorrow.setMilliseconds(0);
      const concatReq = 'users/' + userAddress;
      const userRef = database.ref(concatReq);

      userRef.once('value')
      .then((snapshot) => {
        if (snapshot.val() === null) {  // El usuario no existe, lo creamos
          userRef.set({
            usd: "100.00",
            burpees: "50",
            freeRenames: 2,
            approveUSDT: false,
            approveCBRP: false,
            lng: "en",
            chests: {
              wood: 0,
              silver: 0,
              gold: 0,
              total_bought: 0
            }
          });
        } else {  // El usuario existe, cargamos los datos del usuario (burpees, usd)
          userRef.child("burpees").once('value').then((snapshot) => {
            setBurpees(snapshot.val());
          })
          userRef.child("usd").once('value').then((snapshot) => {
            setUsd(snapshot.val());
          })
          userRef.child("lng").once('value').then((snapshot) => {
            setSelectedLanguage(snapshot.val());
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  });

  return (<>
    <BrowserRouter>
      <Menu
        selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage}
        isLoged={isLoged} setisLoged={setisLoged}
        userAddress={userAddress} setUserAddress={setUserAddress} 
        setSigner={setSigner}
      />
      <Routes>
        <Route index exact path="/" element={<Home />}/>
        <Route exact path="/dapp" element={<><Dapp burpees={burpees} usd={usd} isLoged={isLoged} /></>}>
          <Route exact path="/dapp/store" element={<Store usd={usd} setUsd={setUsd} userAddress={userAddress} />}/>
          <Route exact path="/dapp/transactions" element={<Transactions burpees={burpees} setBurpees={setBurpees} usd={usd} setUsd={setUsd} userAddress={userAddress} signer={signer} setSigner={setSigner} />} />
          <Route exact path="/dapp/doburpees" element={<DoBurpees userAddress={userAddress} setBurpees={setBurpees} burpees={burpees} />} />
          <Route index element={<Store usd={usd} setUsd={setUsd} userAddress={userAddress} />} />
          {/* <Route index element={<Inventory userAddress={userAddress} />} /> */}
          <Route exact path="/dapp/inventory" element={<Inventory userAddress={userAddress} burpees={burpees} setBurpees={setBurpees} />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <div id="loader-container">
        <div className="loader-position">
            <div>
                <div className="loader"></div>
                <h2>{t('appLoaderProcessTxt')}</h2>
            </div>
        </div>
    </div>
    <div id="loader-container-do-burpees">
        <div className="loader-burpees-position">
            <div>
                <div className="loader-burpees"></div>
                <h2 id="loader-timer">15</h2>
                <h3>{t('appLoaderBurpessTxt')}</h3>
            </div>
        </div>
    </div>
  </>
  );
}

export default App;


