import { React, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import Rodal from 'rodal';
import '../../../i18n.ts';
import { useTranslation } from 'react-i18next';

// Assets bebidas
import EDrinkIconTrue from '../../../images/icons/energy-drink-true.png';
import EDrinkIconFalse from '../../../images/icons/energy-drink-false.png';
// Common imports
import c1 from '../../../images/gladiators/common/1.png';
import c2 from '../../../images/gladiators/common/2.png';
import c3 from '../../../images/gladiators/common/3.png';
import c4 from '../../../images/gladiators/common/4.png';
import c5 from '../../../images/gladiators/common/5.png';
import c6 from '../../../images/gladiators/common/6.png';
// Rare imports
import r1 from '../../../images/gladiators/rare/1.png';
import r2 from '../../../images/gladiators/rare/2.png';
import r3 from '../../../images/gladiators/rare/3.png';
import r4 from '../../../images/gladiators/rare/4.png';
import r5 from '../../../images/gladiators/rare/5.png';
import r6 from '../../../images/gladiators/rare/6.png';
// Epic imports
import e1 from '../../../images/gladiators/epic/1.png';
import e2 from '../../../images/gladiators/epic/2.png';
import e3 from '../../../images/gladiators/epic/3.png';
import e4 from '../../../images/gladiators/epic/4.png';
import e5 from '../../../images/gladiators/epic/5.png';
import e6 from '../../../images/gladiators/epic/6.png';
// Legendary imports
import l1 from '../../../images/gladiators/legendary/1.png';
import l2 from '../../../images/gladiators/legendary/2.png';
import l3 from '../../../images/gladiators/legendary/3.png';
import l4 from '../../../images/gladiators/legendary/4.png';
import l5 from '../../../images/gladiators/legendary/5.png';
import l6 from '../../../images/gladiators/legendary/6.png';

export const GladiatorCards = (props) => {
    const contGladiators = props.gladiators.length;
    const [disabledChangeName, setDisabledChangeName] = useState(false);
    const [disabledBuyDrink, setDisabledBuyDrink] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleDrinkModal, setVisibleDrinkModal] = useState(false);
    const [eDrinkPrice, setEDrinkPrice] = useState(0);
    const { t } = useTranslation();
    const rows = [];

    const show = (name, posGlad) => {
        const inputNameElem = document.getElementById("gladiator-name-input");
        const inputPosElem = document.getElementById("gladiator-pos-input");
        if(name !== ""){
            inputNameElem.value = name;
        } else {
            inputNameElem.value = null;
        }
        inputPosElem.value = posGlad;
        if(props.freeRenames < 1){
            if(props.burpees < 2){
                setDisabledChangeName(true);
            }
        }
        setVisible(true);
    };
    const hide = () => {
        setVisible(false);
    };
    async function changeGladName(event){
        event.target.setAttribute("disabled", true);
        const newNameGlad = document.getElementById("gladiator-name-input").value;
        const posGlad = document.getElementById("gladiator-pos-input").value;
        const functions = getFunctions();
        if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        }
        if(props.burpees >= 2 || props.freeRenames > 0){
            const loader = document.getElementById("loader-container");
            loader.classList.remove("hide-loader");
            loader.classList.add("show-loader");
            const changeGladName = async () => {
                const instance = httpsCallable(functions, 'changeGladName');
                instance([posGlad, newNameGlad, props.userAddress])
                .then(res => {
                    setTimeout(function(){
                        toast(res.data.message, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            className: "custom-toast",
                            type: res.data.status,
                            autoClose: 5000, // 5000ms
                            draggable: true,
                            theme: "dark"
                        });
                        let tempGlads = [...props.gladiators];
                        tempGlads[posGlad] = res.data.gladUpdated; 
                        props.setGladiators(tempGlads);
                        props.setBurpees(res.data.burpees);
                        props.setFreeRenames(res.data.freeRenames);
                        event.target.removeAttribute("disabled");
                        hide(); // ocultamos el modal al terminar el proceso.
                        loader.classList.add("hide-loader");
                        loader.classList.remove("show-loader");
                    }, 2500);
                }, err => {
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                event.target.removeAttribute("disabled");
                })
            }
            changeGladName();
        } else {
            toast("No dispone de CBRP suficiente... ("+props.burpees+")", {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "custom-toast",
                type: "error",
                autoClose: 5000, // 5000ms
                draggable: true,
                theme: "dark"
            });
        }
    } 

    const showModalDrink = (posGlad, tier) => {
        if(props.burpees < 2){
            setDisabledBuyDrink(true);
        }
        switch (tier) {
            case "common":
              setEDrinkPrice(2);
              break;
            case "rare":
                setEDrinkPrice(3);
              break;
            case "epic":
                setEDrinkPrice(4.5);
              break;
            case "legendary":
                setEDrinkPrice(5.5);
              break;
            default:
              break;
          }
        setVisibleDrinkModal(true);
        const inputPosElem = document.getElementById("gladiator-pos-input-edrink");
        inputPosElem.value = posGlad;
    }
    const hideModalDrink = () => {
        setVisibleDrinkModal(false);
    };
    
    async function buyEDrink(){
        const posGlad = document.getElementById("gladiator-pos-input-edrink").value;
        const loader = document.getElementById("loader-container");
        loader.classList.remove("hide-loader");
        loader.classList.add("show-loader");
        const functions = getFunctions();
        if(process.env.REACT_APP_GENERALSETTINGS_IS_DEV === "true"){
            connectFunctionsEmulator(functions, "127.0.0.1", 5001);
        }
        const buyEDrink = async () => {
          const instance = httpsCallable(functions, 'buyEDrink');
          instance([posGlad, props.userAddress])
          .then(res => {
            setTimeout(function(){
                toast(res.data.message, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    className: "custom-toast",
                    type: res.data.status,
                    autoClose: 5000, // 5000ms
                    draggable: true,
                    theme: "dark"
                });
                let tempGlads = [...props.gladiators];
                tempGlads[posGlad] = res.data.gladUpdated; 
                props.setGladiators(tempGlads);
                props.setBurpees(res.data.burpees);
                loader.classList.add("hide-loader");
                loader.classList.remove("show-loader");
                hideModalDrink();
            }, 2500);
          }, err => {
            loader.classList.add("hide-loader");
            loader.classList.remove("show-loader");
            hideModalDrink();
          })
      }
      buyEDrink();
    }

    for (let i = 0; i < contGladiators; i++) {
        let lastIdNumber = parseInt(props.gladiators[i].id.split('')[props.gladiators[i].id.length - 1]);
        let srcImage = "";
        let tierStyle = "";
        let tierText = "";
        switch (props.gladiators[i].tier) {
            case "common":
                tierStyle = "tier-emblem common-style";
                tierText = t('common');
            break;
            case "rare":
                tierStyle = "tier-emblem rare-style";
                tierText = t('rare');
            break;
            case "epic":
                tierStyle = "tier-emblem epic-style";
                tierText = t('epic');
            break;
            case "legendary":
                tierStyle = "tier-emblem legendary-style";
                tierText = t('legendary');
            break;
            default: break;
        }
        switch (lastIdNumber) {
            case 0:
            case 1: if(props.gladiators[i].tier === "common"){srcImage = c1; } else if (props.gladiators[i].tier === "rare"){srcImage = r1; } else if (props.gladiators[i].tier === "epic"){srcImage = e1; } else { srcImage = l1; } break;
            case 2:
            case 3: if(props.gladiators[i].tier === "common"){srcImage = c2; } else if (props.gladiators[i].tier === "rare"){srcImage = r2; } else if (props.gladiators[i].tier === "epic"){srcImage = e2; } else { srcImage = l2; } break;
            case 4:
            case 5: if(props.gladiators[i].tier === "common"){srcImage = c3; } else if (props.gladiators[i].tier === "rare"){srcImage = r3; } else if (props.gladiators[i].tier === "epic"){srcImage = e3; } else { srcImage = l3; } break;
            case 6:
            case 7: if(props.gladiators[i].tier === "common"){srcImage = c4; } else if (props.gladiators[i].tier === "rare"){srcImage = r4; } else if (props.gladiators[i].tier === "epic"){srcImage = e4; } else { srcImage = l4; } break;
            case 8: if(props.gladiators[i].tier === "common"){srcImage = c5; } else if (props.gladiators[i].tier === "rare"){srcImage = r5; } else if (props.gladiators[i].tier === "epic"){srcImage = e5; } else { srcImage = l5; } break;
            case 9: if(props.gladiators[i].tier === "common"){srcImage = c6; } else if (props.gladiators[i].tier === "rare"){srcImage = r6; } else if (props.gladiators[i].tier === "epic"){srcImage = e6; } else { srcImage = l6; } break;
            default:
                break;
        }
        rows.push(
            <div className='gladiator-card' key={i} >
                <div className='gladiator-details'>
                    <span>Nº {props.gladiators[i].id}</span><span className={tierStyle}>{tierText}</span>
                </div>
                <div className='gladiator-workouts'>
                    <span><span className={(props.gladiators[i].energia > 0 ? "true-workouts" : "false-workouts")}>{props.gladiators[i].energia}</span> / {props.gladiators[i].energiaMax}
                    <i className={(props.gladiators[i].energia > 0 ? "ml-icons energy-icon ri-water-flash-line" : "ml-icons energy-icon ri-zzz-line")}></i></span>
                </div>
                <div className='img-container'>
                    <img className='gladiator-card-img' src={srcImage} alt=''/>
                    <button className='energy-drink-button' onClick={()=>showModalDrink(i, props.gladiators[i].tier)} disabled={
                        (props.gladiators[i].energyDrink && props.gladiators[i].energia === 0 ? false : true )
                        }><img alt='' className='icon-energy-drink-size' src={
                            (props.gladiators[i].energyDrink && props.gladiators[i].energia === 0 ? EDrinkIconTrue : EDrinkIconFalse )
                            } /></button>
                    {(props.gladiators[i].name !== "" ?
                        <span className='gladiator-name'>{props.gladiators[i].name}</span>
                        :
                        <></>
                    )}
                    <button id='edit-name-button' className='edit-name-button' onClick={() => show(props.gladiators[i].name, i)}><i className="ri-xl ri-edit-fill"></i></button>
                </div>
            </div>
        );
    }
    return ( 
        (rows.length !== 0) ?
        <>
        <div className="dapp-panel">
            <h3>{t('myFockingGladiators')}</h3>
            <div className='gladiator-card-group'>
                {rows}
            </div>
        </div>
        <Rodal className='custom-modal-style' enterAnimation='slideUp' width={550} height={280} visible={visible} onClose={hide}>
            <div>
                <h2>{t('titleModalEditName')}</h2>
                <span>{t('modalEditNameText1')}<span className='true-workouts'>({t('modalEditNameText2')}).</span><br/>{t('modalEditNameText3')}<span className='true-workouts'>2 BRP</span>{t('modalEditNameText4')}</span>
                <h4>{t('modalEditNameText5')}<span className={(props.freeRenames !== 0 ? "true-workouts" : "false-workouts")}>{props.freeRenames}</span></h4>
                <div>
                    <input id='gladiator-name-input' className='custom-input-rename' type="text" maxLength="9" placeholder='Ej: A. LLADOS' required />
                    <input id='gladiator-pos-input' className='display-none' type='number' required />
                </div>
                <button id='open-chest-button' disabled={disabledChangeName} onClick={changeGladName} className='open-chest-button'>{(disabledChangeName ? t('insufficientCBRPAvailable') : t('changeName'))}</button>
            </div>
        </Rodal>
        <Rodal className='custom-modal-style' enterAnimation='slideUp' width={550} height={225} visible={visibleDrinkModal} onClose={hideModalDrink}>
            <div>
                <h2>{t('titleModalEdrink')}</h2>
                <span>{t('modalEdrinkText1')}<span className='true-workouts'>{eDrinkPrice} CBRP.</span><br/>{t('modalEdrinkText2')}<span className='true-workouts'>{t('modalEdrinkText3')}</span>{t('modalEdrinkText4')}</span>
                <h4>{t('modalEdrinkText5')}</h4>
                <div>
                    <input id='gladiator-pos-input-edrink' className='display-none' type='number' required />
                </div>
                <button id='open-chest-button' disabled={disabledBuyDrink} onClick={buyEDrink} className='open-chest-button'>{(disabledBuyDrink ? t('insufficientCBRPAvailable') : t('buy') + " E-DRINK")}</button>
            </div>
        </Rodal>
        <ToastContainer />
        </> : <></>
    );
}